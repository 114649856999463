import React from 'react';

function TwitterIcon({ color }) {

  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12.6009L3.69995 11.7005C2.29896 10.2964 1.44011 8.44185 1.27539 6.46517C1.11067 4.48848 1.65079 2.51738 2.80005 0.900714C3.34747 1.97812 4.10847 2.93272 5.03687 3.70638C5.96526 4.48004 7.04149 5.05656 8.19995 5.40071C8.18627 4.80602 8.29334 4.21458 8.51465 3.66243C8.73595 3.11029 9.06693 2.60844 9.48755 2.18782C9.90817 1.7672 10.4098 1.43647 10.9619 1.21517C11.5141 0.993863 12.1053 0.887038 12.7 0.900714C13.407 0.845067 14.1158 0.982965 14.7501 1.30013C15.3844 1.61729 15.9204 2.10152 16.3 2.70052H19L17.2 5.40071M17.2 5.40071C17.2 11.7007 13.6 16.2005 7.30005 16.2005C3.70005 16.2005 2.3 14.3009 1 12.6009L17.2 5.40071Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default TwitterIcon;