import React from 'react';
import { ArrowDownTable, ArrowLinkTable, ArrowUpTable, DefaultIcon, InfoIconTable, Lock } from 'assets';
import {
    LandingTableCard,
    LandingTableCol,
    LandingTableColContent
} from '../../styled';
import { marketsLogos } from 'constants/constants';
import { roundNumber } from 'utils';
import { TrancheURL } from 'constants/urls';

function LandingTableCardComp({ noBorder, network, contractAddress, cryptoType, trancheType, netAPY, trancheValueUSD, trancheValue }) {
    let trancheTypeWithoutUnderscore = trancheType.replace(/_/g, ' '),
        trancheTypeText = trancheTypeWithoutUnderscore.toLowerCase().charAt(0).toUpperCase() + trancheTypeWithoutUnderscore.toLowerCase().slice(1),
        trancheTypeVar = `${trancheTypeText.split(" ").splice(0, 1).join()} ${trancheTypeText[trancheTypeText.length -1].toUpperCase()}`;
    let cryptoAvax = network === "avax" && cryptoType !== "AVAX";

    return (
        <LandingTableCard noBorder={noBorder}>
            <LandingTableCol width="21%">
                <LandingTableColContent first>
                    <img src={marketsLogos[contractAddress] ? marketsLogos[contractAddress][cryptoType] : DefaultIcon} alt="logo" />
                    <div>
                        <h2>{cryptoType}
                        {
                            cryptoAvax && <span>.e</span>
                        }
                        </h2>
                        <h2>{trancheTypeVar}</h2>
                    </div>
                </LandingTableColContent>
            </LandingTableCol>

            <LandingTableCol width="21%">
                <LandingTableColContent second>
                    <h2>
                        <span>{roundNumber(netAPY, 2)}%</span>
                    </h2>
                    <h2>
                        {trancheType === 'TRANCHE_A' ? 'Fixed' : 'Variable'}
                    </h2>
                </LandingTableColContent>
            </LandingTableCol>

            <LandingTableCol width="21%">
                <LandingTableColContent third>
                    <div>
                        {
                            marketsLogos[contractAddress] &&
                            <img src={marketsLogos[contractAddress].protocolIcon} alt="protocol"/>
                        }
                        <h2>{marketsLogos[contractAddress] && marketsLogos[contractAddress].protocolName}</h2>
                    </div>
                </LandingTableColContent>
            </LandingTableCol>

            <LandingTableCol width="18.5%">
                <LandingTableColContent fourth>
                    <h2>${roundNumber(trancheValueUSD, 2)}</h2>
                    <h2>({roundNumber(trancheValue, 2)} {cryptoType}
                        {
                            cryptoAvax && <span>.e</span>
                        }
                    )
                        
                    </h2>
                </LandingTableColContent>
            </LandingTableCol>

            <LandingTableCol width="18.5%" >
                <LandingTableColContent fifth>
                    <a href={TrancheURL} target="_blank" rel="noopener noreferrer">Deposit {cryptoType}
                        {
                            cryptoAvax && <span>.e</span>
                        }
                     <img src={ArrowLinkTable} alt="arrow" /></a>
                </LandingTableColContent>
            </LandingTableCol>

        </LandingTableCard>
   );
}

export default LandingTableCardComp;