import React, { useEffect, useState } from 'react';
import { ArrowLinkTable, TableBorder } from 'assets';
import {
    LandingTableWrapper,
    LandingTableHeader,
    LandingTableTitle,
    LandingTableContent,
    LandingTableHead,
    LandingTableCards,
    LandingTableCard,
    LandingTableCol,
    LandingTableHeadText,
    LandingLinkTab
} from '../../styled';
import LandingTableCardComp from './LandingTableCard';
import LandingTableCardCompMobile from './LandingTableMobile';
import { LoadingContent } from '../../Analytics/Table/styles/TableComponents';
import { TrancheURL } from 'constants/urls';

function LandingTable({ marketList, loading }) {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 992);
    

    const updateMedia = () => {
      setDesktop(window.innerWidth > 992);
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    });
    return (
        <LandingTableWrapper border={TableBorder}>
            <LandingTableHeader>
                <LandingTableTitle>Tranche Finance Highest APYs</LandingTableTitle>
            </LandingTableHeader>
            <LandingTableContent>
                {   isDesktop &&
                    <LandingTableHead>
                        <LandingTableCol width="21%">
                            <LandingTableHeadText>Asset</LandingTableHeadText>
                        </LandingTableCol>

                        <LandingTableCol width="21%">
                            <LandingTableHeadText>Net APY</LandingTableHeadText>
                        </LandingTableCol>

                        <LandingTableCol width="21%">
                            <LandingTableHeadText>Protocol</LandingTableHeadText>
                        </LandingTableCol>

                        <LandingTableCol width="18.5%">
                            <LandingTableHeadText>Deposits</LandingTableHeadText>
                        </LandingTableCol>

                        <LandingTableCol width="18.5%">

                        </LandingTableCol>
                    </LandingTableHead>
                }
           
                <LandingTableCards>
                {
                    loading ?
                    [...Array(5)].map((i, idx) => (
                        <LandingTableCard noBorder={true}>
                            <div className='loadingCard'>
                                <div className='loadingSecondCol'>
                                    <LoadingContent className='loadingContentCol loadingContentWrapper loadingContent' colorOne="rgba(255,255,255,0.07)" colorTwo="#363661"></LoadingContent>
                                </div>
                                <div className='loadingFifthCol'>
                                    <LoadingContent className='loadingFifthColContent loadingContentWrapper loadingContent' colorOne="rgba(255,255,255,0.07)" colorTwo="#363661"></LoadingContent>
                                </div>
                                <div className='loadingSixthCol'>
                                    <LoadingContent className='loadingSixthColContent loadingContentWrapper loadingContent' colorOne="rgba(255,255,255,0.07)" colorTwo="#363661"></LoadingContent>
                                </div>
                            </div>
                        </LandingTableCard>
                    )) :
                    marketList?.map((market, idx) =>{ 
                        return(
                            <>
                                {isDesktop ?
                                    <LandingTableCardComp key={idx} cryptoType={market.cryptoType} apyStatus={market.apyStatus} netAPY={market.netAPY} trancheType={market.type} trancheValue={market.trancheValue} trancheValueUSD={market.trancheValueUSD} network={market.network} contractAddress={market.contractAddress} noBorder={idx === marketList.length - 1}/> :
                                    <LandingTableCardCompMobile key={idx} cryptoType={market.cryptoType} apyStatus={market.apyStatus} netAPY={market.netAPY} trancheType={market.type} trancheValue={market.trancheValue} trancheValueUSD={market.trancheValueUSD} network={market.network} contractAddress={market.contractAddress} noBorder={idx === marketList.length - 1}/>
                                }
                            </>
                        )
                    })
                }
                </LandingTableCards>
            </LandingTableContent>
        </LandingTableWrapper>
   );
}

export default LandingTable;