import React from 'react';
import { ArrowLinkTable, AuditLanding, SecurityIcon1, SecurityIcon2 } from 'assets';
import {
    SecurityAuditWrapper,
    SecurityAuditSection,
    SecurityAuditSectionTitle,
    SecurityAuditSectionText,
    LandingLinkTab
} from '../styled';


function SecurityAudit() {
    return (
        <SecurityAuditWrapper>
            <SecurityAuditSection width="52%">
                <SecurityAuditSectionTitle>
                    SECURITY &amp; AUDITS
                </SecurityAuditSectionTitle>
                <SecurityAuditSectionText>
                    Tranche is committed to high security standards. All smart contracts are independently reviewed by leading auditors and consultants. Contract code is publicly verifiable, and bug bounty programs are available for security professionals to report vulnerabilities. 
                </SecurityAuditSectionText>
                <LandingLinkTab href="https://github.com/tranche-jibrel/Audits" target="_blank" rel="noopener noreferrer">
                    View Audit Reports
                    <img src={ArrowLinkTable} alt="icon"/>
                </LandingLinkTab>
            </SecurityAuditSection>
            <SecurityAuditSection right width="48%">
                <img src={AuditLanding} alt="security"/>
            </SecurityAuditSection>
        </SecurityAuditWrapper>
    );
}

export default SecurityAudit;