import React from 'react';
import TranchePerformanceChart from './TranchePerformanceChart';

const dimensionsChart1 = {
  width: 1299,
  height: 478,
  margin: { top: 25, right: 35, bottom: 25, left: 35 },
  header: 60
};
const labelsChart1 = {
  mainTitle: 'Performance',
  netApyToggleLabel: 'NET APY',
  sliceApyToggleLabel: 'SLICE APY',
  hoverApy: 'NET APY',
  hoverSubtextA: 'TRANCHE A VALUE',
  hoverSubtextB: 'TRANCHE B VALUE',
  aTranche: 'Tranche A',
  bTranche: 'Tranche B',
  aTrancheHover: 'Select to display Tranche A data',
  bTrancheHover: 'Select to display Tranche B data'
};

function PerformanceChart({ theme, chartData , trancheType, changeTranche}) {
  return (
      <div className={`mainPage ${theme}`}>
        { chartData &&
          <TranchePerformanceChart
            theme={theme}
            changeTrancheType={changeTranche}
            trancheType={trancheType}
            data={chartData && chartData}
            dimensions={dimensionsChart1}
            labels={labelsChart1}
          />
        }
      </div>
  );
}

export default PerformanceChart;