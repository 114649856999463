import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { ModeThemes } from 'constants/constants';
import { 
    HeaderTabsWrapper,
    HeaderTab
} from '../styles/AnalyticsComponents';
const useResizeObserver = (ref) => {
    const [dimensions, setDimensions] = useState(null);
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setDimensions(entry.contentRect);
            });
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);

    return dimensions;
};

const  TranchePerformanceChart = ({ data = {}, dimensions = {}, labels = {}, theme, changeTrancheType, trancheType }) => {
    const [apyType, setApyType] = useState('NET');
    const [apyToggle, setApyToggle] = useState(true);
    
    const svgRef = useRef();
    const wrapperRef = useRef();
    const wrapDim = useResizeObserver(wrapperRef);


    let { height, margin = {}, header } = dimensions;

    const svgHeight = height - header
    const contentOffset = 70;
    const curve = d3.curveCatmullRom;
    let rightMargin;
    const initialSelectedIndex = data.trancheAValueUSD.length - 2;

    const setActiveNet = () => {
        setApyToggle(true); 
        setApyType('NET');
        const svg = d3.select(svgRef.current);
        svg.select('.hidden-toggle').dispatch('mousedown')
    };

    const setActiveSlice = () => {
        setApyToggle(false); 
        setApyType('SLICE');
        const svg = d3.select(svgRef.current);
        svg.select('.hidden-toggle').dispatch('mousedown')
    };

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        if (!wrapDim) return;

        let width = wrapDim.width;
        
        const mobileView = width > 768 ? false : true;

        // eslint-disable-next-line
        rightMargin = margin.right

        const dataProps = [
            { line: "netAApy", bar: "trancheAValueUSD", sliceLine: "sliceAApy" },
            { line: "netBApy", bar: "trancheBValueUSD", sliceLine: "sliceBApy" }
        ];

        let selectedData = dataProps[0];
        let trancheToggle = true;
        // eslint-disable-next-line
        let hoveredIndex = -1;

        //remove all svg elements
        svg.selectAll("*").remove();
        svg.attr('height', svgHeight).attr('width', width).style('overflow', 'overlay')

        const apyFormat = d3.format(".2%");
        const dateFormat = d3.timeFormat("%b %d");
        const rectHeight = svgHeight / 5;

        const priceFormat = d3.format("($,.2f");
        const lineTrancheA = d3
            .line()
            .x((d, i) => x(i) + x.bandwidth() / 2)
            .y((d) => yLineTrancheA(d))
            .curve(curve);

        const lineTrancheB = d3
            .line()
            .x((d, i) => x(i) + x.bandwidth() / 2)
            .y((d) => yLineTrancheB(d))
            .curve(curve);

        const x = d3
            .scaleBand()
            .domain(d3.range(data.date.length))
            .range([margin.left, width - rightMargin])
            .padding(0.1);

        const hoverX = x.copy().padding(0);

        const yLineTrancheA = d3
            .scaleLinear()
            .domain([Math.min(0,d3.min(data.netAApy)), d3.max(data.netAApy)])
            .range([rectHeight * 0.8, 0]);

        const yLineTrancheB = d3
            .scaleLinear()
            .domain([Math.min(0,d3.min(data.netBApy)), d3.max(data.netBApy)])
            .range([rectHeight * 0.8, 0]);

        const y = d3
            .scaleLinear()
            .domain([
                0,
                d3.max([
                    d3.max(data.trancheAValueUSD),
                    d3.max(data.trancheBValueUSD)
                ])
            ])
            .range([rectHeight, 0]);

        let lineGroup = svg
            .append("g")
            .attr("class", "line-group")
            .attr("transform", `translate(0,${margin.top + contentOffset})`);

        const bTrancheLines = lineGroup.append('g');
        const aTrancheLines = lineGroup.append('g');

        const bTrancheLineBackdrop = bTrancheLines
            .append("path")
            .attr('class', 'backdrop-line')

        var bTrancheline = bTrancheLines
            .append("path")
            .attr('class', 'tranche-line trancheB')
            .attr("opacity", 0.2);

        const aTrancheLineBackdrop = aTrancheLines
            .append("path")
            .attr('class', 'backdrop-line')

        var aTrancheline = aTrancheLines
            .append("path")
            .attr('class', 'tranche-line trancheA')
            .attr("opacity", 0.8);


        let hoverGroup = svg
            .append("g")
            .attr("transform", `translate(0,${margin.top + contentOffset})`);

        let hoverLine = hoverGroup
            .append("line")
            .attr('class', 'hover-line')

        let hoverCircle = hoverGroup
            .append("circle")
            .attr("r", 10)
            .attr('class', 'hover-circle hover-element')
            .attr("opacity", 0);

        let dateText = svg
            .append("text")
            .attr('class', 'tranche-text text-14')
            .attr("opacity", 0)

        let rects = svg
            .append("g")
            .attr('class', 'bars')
            .attr("transform", `translate(0,${svgHeight - margin.top - rectHeight - margin.bottom - header + 40})`);

        let hoverText = rects
            .append("text")
            .attr('class', mobileView ? 'tranche-text text-18 hover-element' : 'tranche-text text-24 hover-element')

        let subtitleHoverText = rects
            .append("text")
            .attr('class', 'tranche-text text-10 hover-element')
            .attr("opacity", 0)
            .text(trancheType ? labels.hoverSubtextA : labels.hoverSubtextB);        

        const legendSize = 15;
        // const legendGroup = svg.append('g').attr('transform', `translate(${width / 2}, 15)`)
        // const aTrancheLegend = legendGroup.append('g');
        // aTrancheLegend
        //     .attr('transform', `translate(${- 5 - legendSize},0)`)
        //     .attr('opacity', 0.8)
        //     .attr('cursor', 'pointer')
        //     .on("mousedown", e => changeTranche(true))

        // aTrancheLegend.append('title').text(labels.aTrancheHover)

        // aTrancheLegend
        //     .append('text')
        //     .attr('class', 'tranche-text text-12 text-bold')
        //     .attr('dy', 12)
        //     .attr('dx', -10)
        //     .style('text-anchor', 'end')
        //     .text(`${labels.aTranche} ${apyToggle ? labels.netApyToggleLabel : labels.sliceApyToggleLabel}`)
        // aTrancheLegend.append('rect').attr('class', 'legend-rect trancheA')
        // const bTrancheLegend = legendGroup
        //     .append('g');
        // bTrancheLegend.append('title').text(labels.bTrancheHover)
        // bTrancheLegend
        //     .attr('transform', `translate(${5},0)`)
        //     .attr('cursor', 'pointer')
        //     .attr('opacity', 0.2)
        //     .on("mousedown", e => changeTranche(false));
        // bTrancheLegend.append('rect').attr('class', 'legend-rect trancheB')
        // bTrancheLegend.append('text')
        //     .attr('class', 'tranche-text text-12 text-bold')
        //     .style('text-anchor', 'start')
        //     .attr('dy', 12)
        //     .attr('dx', 20)
        //     .text(`${labels.bTranche} ${apyToggle ? labels.netApyToggleLabel : labels.sliceApyToggleLabel}`)


        //legendGroup.attr('opacity', mobileView ? 1 : 0)

        let trancheLabels = lineGroup.append('g');
        let aTrancheLabel = trancheLabels
            .append("text")
            .attr('class', 'tranche-text text-12 text-bold')
            .attr('dx', 28)
            .attr("x", width - rightMargin)
            .attr("y", yLineTrancheA(data.netAApy[data.netAApy.length - 1]) + 4)
            .style("cursor", "pointer")
            .style("opacity", trancheType ? 0.8 : 0.2)
            .text(labels.aTranche)
            .on("mousedown", function (e) {
                changeTranche(true)
            });

        let bTrancheLabel = trancheLabels
            .append("text")
            .attr('class', 'tranche-text text-12 text-bold')
            .attr('dx', 28)
            .attr("x", width - rightMargin)
            .attr("y", yLineTrancheB(data.netBApy[data.netBApy.length - 1]) + 4)
            .style("cursor", "pointer")
            .style("opacity", trancheType ? 0.2 : 0.8)
            .text(labels.bTranche)
            .on("mousedown", function (e) {
                changeTranche(false)
            });

        //trancheLabels.attr('opacity', mobileView ? 0 : 1)
        trancheLabels.attr('opacity', 0)
        // eslint-disable-next-line
        let toggleOverlay = svg
            .append("rect")
            .attr('class', 'hidden-toggle')
            .on("mousedown", function (e) {
                // aTrancheLegend.select('text').text(`${labels.aTranche} ${apyToggle ? labels.netApyToggleLabel : labels.sliceApyToggleLabel}`)
                // bTrancheLegend.select('text').text(`${labels.bTranche} ${apyToggle ? labels.netApyToggleLabel : labels.sliceApyToggleLabel}`)
                updateLines();
            });

        let lineValueText = lineGroup
            .append("text")
            .attr('class', 'tranche-text text-normal hover-element')
            .attr("opacity", 0);

        let lineValueTextSubtitle = lineGroup
            .append("text")
            .attr('class', 'tranche-text text-10 hover-element')
            .attr("opacity", 0);

        const hoverRects = svg.append('g').attr('class', 'hoverRectGroup');

        hoverRects.selectAll('.hoverRect')
            .data(data[selectedData.bar])
            .join("rect")
            .attr('class', 'hoverRect')
            .attr('fill', 'red')
            .attr('opacity', .0)
            .attr("x", (d, i) => hoverX(i))
            .attr("width", hoverX.bandwidth())
            .attr("y", header + 15)
            .attr("height", svgHeight - margin.bottom);

        let hoverSelection = svg.selectAll('.hoverRect');
        hoverSelection
            .on("mousemove", function (event, d) {
                const e = hoverSelection.nodes();
                const i = e.indexOf(this);
                updateHover(i);
            })
            .on("mouseout", function (event, d) {
                clearHover();
                hoveredIndex = -1;
            });

        updateRects();
        updateLines();
        updateHover(initialSelectedIndex);

        trancheToggle = trancheType;
        selectedData = trancheType ? dataProps[0] : dataProps[1];

        if (trancheToggle) aTrancheLines.raise();
        else bTrancheLines.raise();
        hoverCircle.classed('trancheB', !trancheType);
        d3.selectAll('.hover-element').raise();

        updateRects();
        updateHover(initialSelectedIndex);
        aTrancheline.transition().duration(1000).style("opacity", trancheType ? 0.8 : 0.2);
        bTrancheline.transition().duration(1000).style("opacity", trancheType ? 0.2 : 0.8);
        aTrancheLabel.transition().duration(1000).style("opacity", trancheType ? 0.8 : 0.2);
        bTrancheLabel.transition().duration(1000).style("opacity", trancheType ? 0.2 : 0.8);
        // aTrancheLegend.transition().duration(1000).style("opacity", trancheType ? 1 : 0.2);
        // bTrancheLegend.transition().duration(1000).style("opacity", trancheType ? 0.2 : 1);

        const changeTranche = (aTrancheSelected) => {
            changeTrancheType(aTrancheSelected)
            trancheToggle = aTrancheSelected;
            selectedData = aTrancheSelected ? dataProps[0] : dataProps[1];

            aTrancheline.transition().duration(1000).style("opacity", aTrancheSelected ? 0.8 : 0.2);
            bTrancheline.transition().duration(1000).style("opacity", aTrancheSelected ? 0.2 : 0.8);
            aTrancheLabel.transition().duration(1000).style("opacity", aTrancheSelected ? 0.8 : 0.2);
            bTrancheLabel.transition().duration(1000).style("opacity", aTrancheSelected ? 0.2 : 0.8);
            // aTrancheLegend.transition().duration(1000).style("opacity", aTrancheSelected ? 1 : 0.2);
            // bTrancheLegend.transition().duration(1000).style("opacity", aTrancheSelected ? 0.2 : 1);

            if (trancheToggle) aTrancheLines.raise();
            else bTrancheLines.raise();
            hoverCircle.classed('trancheB', !aTrancheSelected);
            d3.selectAll('.hover-element').raise();

            updateRects();
            updateHover(initialSelectedIndex);
        }

        function updateRects() {
            rects
                .selectAll("rect")
                .data(data[selectedData.bar])
                .join("rect")
                .attr("x", (d, i) => x(i))
                .attr("class", (d, i) => `rect-${i} bar`)
                .attr("width", x.bandwidth())
                .transition()
                .duration(1000)
                .attr("y", (d) => y(d))
                .attr("height", (d) => y(0) - y(d));
        }

        function updateLines() {
            var aData = apyToggle
                ? data[dataProps[0]["line"]]
                : data[dataProps[0]["sliceLine"]];

            var bData = apyToggle
                ? data[dataProps[1]["line"]]
                : data[dataProps[1]["sliceLine"]];

            yLineTrancheA.domain([0, d3.max([d3.max(aData), d3.max(bData)])]);

            bTrancheLineBackdrop.attr("d", lineTrancheB(bData));
            bTrancheline.attr("d", lineTrancheB(bData));

            aTrancheLineBackdrop.attr("d", lineTrancheA(aData));
            aTrancheline.attr("d", lineTrancheA(aData));
        }

        function updateHover(index) {
            // if (index == hoveredIndex) return;
            // else hoveredIndex = index;

            d3.selectAll(".bar").classed("selected", false);

            var activeProp = apyToggle ? "line" : "sliceLine";
            d3.select(`.rect-${index}`).classed("selected", true).classed('trancheB', !trancheToggle);

            let xOffset = x(index) + x.bandwidth() / 2;
            var date = new Date(data.date[index]);
            var dateString = date ? dateFormat(date) : "";

            const hoverLineOffset = svgHeight - margin.bottom - margin.top - header - 2 * rectHeight - 40;
            const yValue = trancheToggle ? yLineTrancheA(data[selectedData[activeProp]][index]) : yLineTrancheB(data[selectedData[activeProp]][index]);
            
            hoverLine
                .attr("opacity", 0.65)
                .attr("x1", xOffset)
                .attr("x2", xOffset)
                .attr("y1", yValue)
                .attr(
                    "y2", hoverLineOffset + y(data[selectedData.bar][index])
                );

            hoverText
                .attr("opacity", 1)
                .attr("x", xOffset)
                .attr("y", y(data[selectedData.bar][index]) - 40)
                .text(priceFormat(data[selectedData.bar][index]))

            subtitleHoverText
                .attr("opacity", 0.65)
                .attr("x", xOffset)
                .attr("y", y(data[selectedData.bar][index]) - 20)

            dateText
                .attr("opacity", 1)
                .attr("x", xOffset)
                .attr("y", svgHeight - margin.bottom - header + 35)
                .text(dateString);

            hoverCircle
                .attr("opacity", 1)
                .attr("cx", xOffset)
                .attr("cy", yValue).raise();
            lineValueText
                .attr("opacity", 1)
                .attr("x", xOffset)
                .attr("y", yValue - 40)
                .text(apyFormat(data[selectedData[activeProp]][index] / 100));

            var apyTitle = apyToggle ? "NET APY" : "SLICE APY";
            lineValueTextSubtitle
                .attr("opacity", 0.65)
                .attr("x", xOffset)
                .attr("y", yValue - 20)
                .text(apyTitle);

            // d3.selectAll('.hover-element').raise();

        }

        function clearHover() {
            hoverLine.attr("opacity", 0);
            subtitleHoverText.attr("opacity", 0);
            hoverText.attr("opacity", 0).text();
            dateText.attr("opacity", 0).text();
            hoverCircle.attr("opacity", 0);
            lineValueText.attr("opacity", 0).text();
            lineValueTextSubtitle.attr("opacity", 0);
            d3.selectAll(".bar").classed("selected", false);
        }



    }, [wrapDim, apyToggle, trancheType]);



    return (
        <div ref={wrapperRef}
            style={{
                width: "100%",
                minHeight: svgHeight + header,
                maxHeight: svgHeight + header,
            }}
            className='chart-wrapper'>
            <p className='chart-title chartFirst'>{(trancheType ? labels.aTranche : labels.bTranche)  + ' ' + labels.mainTitle}
             <HeaderTabsWrapper
                stakeBoxBackground={ModeThemes[theme].stakeBoxBackground}
                stakeModalBoxBackground={ModeThemes[theme].stakeModalBoxBackground}
                StakeModalNavigationBorder={ModeThemes[theme].StakeModalNavigationBorder}
                toggleBackground={ModeThemes[theme].toggleBackground}
                theme={theme}
                apyType={apyType}
                firstChart
                left={apyType === 'NET'}
                right={apyType === 'SLICE'}
            >
                <HeaderTab
                    stakeModalBoxShadow={ModeThemes[theme].stakeModalBoxShadow}
                    stakeModalBoxBackground={ModeThemes[theme].stakeModalBoxBackground}
                    StakeModalNavigationText={ModeThemes[theme].StakeModalNavigationText}
                    theme={theme}
                    active={apyType === 'NET'}
                    onClick={setActiveNet}
                >
                    NET APY
                </HeaderTab>
                <span></span>
                <HeaderTab
                    stakeModalBoxShadow={ModeThemes[theme].stakeModalBoxShadow}
                    stakeModalBoxBackground={ModeThemes[theme].stakeModalBoxBackground}
                    StakeModalNavigationText={ModeThemes[theme].StakeModalNavigationText}
                    theme={theme}
                    active={apyType === 'SLICE'}
                    onClick={setActiveSlice}
                >
                    SLICE APY
                </HeaderTab>
            </HeaderTabsWrapper>
             </p>
            <hr></hr>
            <svg ref={svgRef}>
            </svg>
        </div>
    );
}

export default TranchePerformanceChart;