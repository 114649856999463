import React, { useState } from 'react';
import {
  FooterWrapper,
  FooterContent,
  FooterLinks,
  FooterText,
  FooterSocial
} from '../../styled';
import TwitterIcon from './TwitterIcon';
import DiscordIcon from './DiscordIcon';
import MediumIcon from './MediumIcon';

function Footer() {
 const [Hovered, setHovered] = useState('')
  return (
    <FooterWrapper>
        <FooterContent>
          <FooterLinks>
            {/* <a href="/">Privacy &amp; Terms</a> */}
            <a href="https://discord.com/invite/Nv44PTdF3K" target="_blank" rel="noopener noreferrer">Contact Us</a>
            <a href="https://docs.tranche.finance/" target="_blank" rel="noopener noreferrer">Docs</a>
          </FooterLinks>
          <FooterText>Copyright &copy; 2022 Tranche Finance</FooterText>
          <FooterSocial>
            <a href="https://tranchefinance.medium.com" target="_blank" rel="noopener noreferrer" onMouseEnter={() => setHovered('medium')} onMouseLeave={() => setHovered('')}>
              <MediumIcon color={Hovered === 'medium' ? "#4441CF" : "#FFFFFF"} />
            </a>
            <a href="https://twitter.com/TrancheFinance" target="_blank" rel="noopener noreferrer" onMouseEnter={() => setHovered('twitter')} onMouseLeave={() => setHovered('')}>
              <TwitterIcon color={Hovered === 'twitter' ? "#4441CF" : "#FFFFFF"} />
            </a>
            <a href="https://discord.com/invite/Nv44PTdF3K" target="_blank" rel="noopener noreferrer" onMouseEnter={() => setHovered('discord')} onMouseLeave={() => setHovered('')}>
              <DiscordIcon color={Hovered === 'discord' ? "#4441CF" : "#FFFFFF"} />
            </a>
          </FooterSocial>
        </FooterContent>
    </FooterWrapper>
  );
}
export default Footer;