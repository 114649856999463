import React from 'react';

function MediumIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.89729 7.85597C5.91717 7.63949 5.84227 7.42553 5.69561 7.27984L4.20168 5.2963V5H8.84034L12.4258 13.6667L15.578 5H20V5.2963L18.7227 6.64609C18.6126 6.7386 18.5579 6.89066 18.5808 7.04115V16.9588C18.5579 17.1093 18.6126 17.2614 18.7227 17.3539L19.9701 18.7037V19H13.6956V18.7037L14.9879 17.321C15.1148 17.1811 15.1148 17.1399 15.1148 16.9259V8.90946L11.5219 18.9671H11.0364L6.85341 8.90946V15.6502C6.81853 15.9336 6.90393 16.2189 7.08497 16.4239L8.76564 18.6708V18.9671H4V18.6708L5.68067 16.4239C5.86039 16.2186 5.94081 15.9314 5.89729 15.6502V7.85597Z" fill={color}/>
    </svg>
  );
}

export default MediumIcon;