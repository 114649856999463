import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};


function DepositsChart({ data = [], dimensions = {}, labels = {} }) {

  const svgRef = useRef();
  const wrapperRef = useRef();
  const wrapDim = useResizeObserver(wrapperRef);


  const { width, height, margin = {}, header } = dimensions;
  const { mainTitle, upperLabel, leftLabel, lowerLabel, lowerSubLabel } = labels;
  const designMargin = { bottom: 25, top: 90, left: 25 }
  const svgWidth = width;
  const svgHeight = height - header
  const backgroundColor = "#1B1A40";

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    if (!wrapDim) return;


    const width = wrapDim.width;
    const height = wrapDim.height;

    svg.selectAll("*").remove();
    svg.attr('height', height).attr('width', width)

    const aTrancheData = data[2]
    const bTrancheData = data[3]
    const aTrancheDataLabels = data[0]
    const bTrancheDataLabels = data[1]

    const trancheFormat = d3.format(".2%");
    const solvencyFormat = d3.format(".2f");
    // const solvencyFormat = d3.format(".1f");
// 

    const fixedCurrentSIRlabel = data[4]
    const fixedCurrentSIRlocation = data[5]

    const fixedCurrentSIR = Math.floor(aTrancheData.length * fixedCurrentSIRlocation);
    let selectedValue = fixedCurrentSIR

    const maxTrancheB = d3.min(bTrancheData);
    const maxTrancheA = d3.max(aTrancheData);

    const line = d3
      .line()
      .x((d, i) => x(i))
      .y((d, i) => y(d))
      .curve(d3.curveCatmullRom);

    const x = d3
      .scaleLinear()
      .domain([0, aTrancheData.length - 1])
      .range([margin.left, width - margin.right])
      .clamp(true);

    const xSolvency = d3
      .scaleBand()
      .domain(d3.range(aTrancheData.length))
      .range(x.range())
      .padding(0.0);

    const y = d3
      .scaleLinear()
      .domain([d3.min(bTrancheData), d3.max(aTrancheData)])
      .range([svgHeight - margin.bottom, margin.top]);

    const chartGroup = svg
      .append("g")
      .attr("transform", `translate(0,${margin.top})`);


    function update(xPos) {
      var index = Math.floor(x.invert(xPos));
      updateHoverElements(index);
    }

    const aTrancheLine = chartGroup
      .append("path")
      .attr('class', 'tranche-line upper')
      .attr("d", line(aTrancheData));

    const aTrancheLabel = chartGroup
      .append("text")
      .attr('class', 'tranche-text text-16 text-bold text-end')
      .attr("x", x.range()[1])
      .attr("y", y(0) - 55)
      .text(labels.trancheA);

    const bTrancheLine = chartGroup
      .append("path")
      .attr('class', 'tranche-line lower')
      .attr("d", line(bTrancheData));

    const bTrancheLabel = chartGroup
      .append("text")
      .attr('class', 'tranche-text text-16 text-bold text-end')
      .attr("x", x.range()[1])
      .attr("y", y(0) + 65)
      .text(labels.trancheB);


    var ySolvencyHeight = y.range()[1] - 20;

    const abscissa = chartGroup
      .append("line")
      .attr("x1", x.range()[0])
      .attr("x2", x.range()[1])
      .attr("y1", y(0))
      .attr("y2", y(0))
      .attr('class', 'abscissa')

    const selectedOrdinate = chartGroup
      .append("line")
      .attr("x1", xSolvency(selectedValue))
      .attr("x2", xSolvency(selectedValue))
      .attr("y1", ySolvencyHeight)
      //.attr("y2", y(bTrancheData[42]) - 30)
      .attr("y2", y.range()[0])
      .attr('class', 'selected-ordinate')

    var verticalFrame = chartGroup
      .append("line")
      .attr("x1", x.range()[0] - 20)
      .attr("x2", x.range()[0] - 20)
      .attr("y1", y.range()[0] + 20)
      .attr("y2", y.range()[1] - 20)
      .attr('class', 'frame')

    const verticalLabelGroup = chartGroup
      .append("g")
      .attr("transform", `translate(${margin.left - 35},${y(0)})`);
    const verticalLabel = verticalLabelGroup
      .append("text")
      .attr("class", "chart2-mainlabel text-middle")
      .text(leftLabel)
      .attr("transform", "rotate(-90)");

    chartGroup
      .append("g")
      .attr("transform", `translate(${margin.left - 35},${y.range()[0]})`)
      .append("text")
      .attr("class", "chart2-mainlabel text-middle")
      .attr('dy', 20)
      .text("0")

    const horizontalFrame = chartGroup
      .append("line")
      .attr("x1", x.range()[0] - 20)
      .attr("x2", x.range()[1] + 20)
      .attr("y1", y.range()[0] + 20)
      .attr("y2", y.range()[0] + 20)
      .attr('class', 'frame')

    const horizontalLabel = chartGroup
      .append("text")
      .attr("class", "chart2-mainlabel text-start")
      .attr("x", x.range()[1] - 200)
      .attr("y", y.range()[0] + 50)
      .text(lowerLabel);

    chartGroup
      .append("text")
      .attr("class", "chart2-mainlabel text-12 text-start")
      .attr("x", x.range()[1] - 200)
      .attr("y", y.range()[0] + 70)
      .text(lowerSubLabel);

    const solvencyLabel = chartGroup
      .append("text")
      .attr('class', 'chart2-mainlabel text-middle')
      .attr("x", (x.range()[1] - x.range()[0]) / 2 + x.range()[0])
      .attr("y", ySolvencyHeight - 50)
      .text(upperLabel);

    const solvencyLine = chartGroup
      .append("line")
      .attr("x1", x.range()[0])
      .attr("x2", x.range()[1])
      .attr("y1", ySolvencyHeight)
      .attr("y2", ySolvencyHeight)
      .attr('class', 'solvency-line');

    const solvencyCurrent = chartGroup
      .append("line")
      // .attr("x1", xSolvency(selectedValue))
      // .attr("x2", xSolvency(selectedValue))
      .attr("x1", xSolvency(fixedCurrentSIR))
      .attr("x2", xSolvency(fixedCurrentSIR))
      .attr("y1", ySolvencyHeight)
      .attr("y2", ySolvencyHeight + 30)
      .attr("class", 'solvency-line solvency-current')

    const solvencyCurrentLabel = chartGroup
      .append("text")
      // .attr("x", x(selectedValue))
      .attr("x", xSolvency(fixedCurrentSIR))
      .attr("y", ySolvencyHeight + 50)
      .attr('class', 'tranche-text text-14')
      .text(labels.current);

    const solvencyCircle = chartGroup
      .append("circle")
      //.attr("cx", xSolvency(selectedValue))
      .attr("cx", xSolvency(fixedCurrentSIR))
      .attr("cy", ySolvencyHeight)
      .attr("r", 7)
      .attr('class', 'solvency-circle')

    const solvencyValueLabel = chartGroup
      .append("text")
      .attr('class', 'tranche-text text-16')
      // .attr("x", xSolvency(selectedValue))
      .attr("x", xSolvency(fixedCurrentSIR))
      .attr("y", ySolvencyHeight - 15)
      //.text(solvencyFormat(selectedValue / 100));
      .text(fixedCurrentSIRlabel);

    const aTrancheCircle = chartGroup
      .append("circle")
      .attr("cx", xSolvency(selectedValue))
      .attr("cy", y(aTrancheData[selectedValue]))
      .attr('class', 'tranche-circle upper')
      .attr("r", 7)

    const aTrancheCircleLabel = chartGroup
      .append("text")
      .attr('class', 'tranche-text text-16')
      .attr("x", xSolvency(selectedValue))
      .attr("y", y(aTrancheData[selectedValue]) - 15)
      // .text(trancheFormat(aTrancheData[selectedValue] / 100));
      // .text(trancheFormat(aTrancheDataLabels[selectedValue] / 100));

    const bTrancheCircle = chartGroup
      .append("circle")
      .attr("cx", xSolvency(selectedValue))
      .attr("cy", y(bTrancheData[selectedValue]))
      .attr("r", 7)
      .attr('class', 'tranche-circle lower')

    const bTrancheCircleLabel = chartGroup
      .append("text")
      .attr('class', 'tranche-text text-16')
      .attr("x", xSolvency(selectedValue))
      .attr("y", y(bTrancheData[selectedValue]) + 25)
      // .text(trancheFormat(-bTrancheData[selectedValue] / 100));
      // .text(trancheFormat(-bTrancheDataLabels[selectedValue] / 100));

    const hoverRect = chartGroup
      .append("rect")
      .attr("opacity", 0)
      .attr("x", margin.left)
      .attr("width", width - margin.left - margin.right)
      .attr("height", height)
      .attr('cursor', 'pointer')
      .on("mousedown", (event, d) => {
        update(event.offsetX);
      })
      .call(d3.drag().on("drag end", (event, d) => update(event.x)));

    const helperText = chartGroup
      .append('foreignObject')
      .classed('helper-text', true)
      .attr('width', 130)
      .attr('height', 100)
      .attr('x', xSolvency(selectedValue) + 10)
      .attr('y', (ySolvencyHeight + y(aTrancheData[selectedValue]) / 2) - 45)
      .text(labels.annotation)

    function updateHoverElements(index) {
      helperText.transition().duration(250).style('opacity', 0).remove()
      var xOffset = xSolvency(index);

      bTrancheCircle.attr("cx", xOffset).attr("cy", y(bTrancheData[index]));
      aTrancheCircle.attr("cx", xOffset).attr("cy", y(aTrancheData[index]));
      solvencyCircle.attr("cx", xOffset).attr("cy", ySolvencyHeight);

      aTrancheCircleLabel
        .attr("x", xOffset)
        .attr("y", y(aTrancheData[index]))
        .attr("dy", -(1 - aTrancheData[index] / maxTrancheA) * 15)
        .attr("dx", (d, i) => {
          return (aTrancheData[index] / maxTrancheA) * 40;
        })
        // .text(trancheFormat(aTrancheData[index] / 100));
        // .text(trancheFormat(aTrancheDataLabels[index] / 100));


      bTrancheCircleLabel
        .attr("x", xOffset)
        .attr("y", y(bTrancheData[index]))
        .attr("dy", (1 - bTrancheData[index] / maxTrancheB) * 30)
        .attr("dx", (bTrancheData[index] / maxTrancheB) * 40)
        // .text(trancheFormat(-bTrancheData[index] / 100));
        // .text(trancheFormat(-bTrancheDataLabels[index] / 100));

      // solvencyValueLabel
      //   .attr("x", xOffset)
      //   .attr("y", ySolvencyHeight - 15)
      //   .text(solvencyFormat(index / 100));

      selectedOrdinate
        .attr("x1", xOffset)
        .attr("x2", xOffset)
        .lower();
      // .attr("y2", y(aTrancheData[index]) - 30);

      // invisibleHoverRect.attr('x', xOffset - hoverWidth / 2)
    }

  }, [wrapDim]);
  return (
    <div
      ref={wrapperRef}
      style={{
        width: "100%",
        minHeight: svgHeight + header,
        maxHeight: svgHeight + header,
        maxWidth: svgWidth
      }}
      className='chart-wrapper'>
      <p className='chart-title'>{mainTitle}</p>
      <hr></hr>
      <svg ref={svgRef}>
      </svg>
    </div>
  );
}

export default DepositsChart;