import styled, { keyframes } from 'styled-components';
import ReactSlider from 'react-slider';


const LinesAnimation = keyframes`
  from { transform: rotateX( 84deg ) translateY( 0 ); }
  to { transform: rotateX( 84deg ) translateY( 100px ); }
`

const LayoutWrapper = styled.div`
  overflow-x: hidden;
`
const Background = styled.div`
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100vh;
`
const BackgroundSky = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 55%;
  background-color: #FFFA02;
	background: linear-gradient(#2e006f 75%, #5e1fba);
`

const BackgroundSkyStars = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const BackgroundSkyStar = styled.div`
  position: absolute;
  left: ${props => props.left};
  top: ${props => props.top};;
  transform: s${props => props.transform};
  border-radius: 50%;
  width: 2px;
  height: 2px;
  background-color: #fff;
`

const BackgroundGround = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 45%;
  bottom: 0;
  border-top: 2px solid #6d1ff0;
  background-color: #6302ee;
`

const BackgroundLinesWrapper = styled.div`
  height: 100%;
  perspective: 1000px;
  perspective-origin: center top;
`

const BackgroundLines = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: top center;
  animation: 0.35s linear infinite ${LinesAnimation};
`
const BackgroundVerticalLines = styled.div`
  position: absolute;
  left: calc( ( 900% - 100% ) / -2 );
  width: 900%;
  height: 500%;
  display: flex;
	justify-content: center;
`
const BackgroundVerticalLine = styled.div`
  width: 100%;
  height: 100%;
  background-color: #844af3;
  width: 3px;
  + div{
    margin-left: 48px;
  }
`

const BackgroundHorizontalLines = styled.div`
  position: absolute;
  left: calc( ( 900% - 100% ) / -2 );
  width: 900%;
  height: 500%;
`
const BackgroundHorizontalLine = styled.div`
  width: 100%;
  height: 100%;
  background-color: #844af3;
  height: 3px;
  + div{
    margin-top: 98px;
  }
`
const BackgroundShadow = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient( #6302ee 0%, transparent );
`


const LayoutContent = styled.div`
  @media(max-width: 992px){
    background-image: url(${props => props.background});
    background-repeat: no-repeat;
    background-position: 50% 30px;
  }
`

const Container = styled.div`
  
`

const LandingWrapper = styled.div`
  min-height: 100vh;
`
const LandingContent = styled.div`
`


const LandingTextWrapper = styled.div`
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  margin-top: 200px;
  display: flex;
  position: relative;
  @media(max-width: 900px){
    margin: 175px 0 150px 0;
  }
`

const LandingLogo = styled.div`
  img{
    max-width: 200px;
    width: 100%;
    @media(max-width: 900px){
      width: 50%;
    }
`
const LandingTextContent = styled.div`
  font-family: 'Inter', sans-serif;
  margin: 0 auto;
  display: inline-block;
  z-index: 2000;
  @media(max-width: 900px){
    margin: -10px auto -20px auto;
  }
  h2:nth-child(1){
    font-weight: bold;
    font-size: 50px;
    line-height: 61px;
    color: #fff;
    margin: 15px auto;
    background: -webkit-linear-gradient(#676767, #EBEBEB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 2000;
    span{
      display: contents;
    }
    img{
      height: 40px;
    }
    @media(max-width: 900px){
      display: inline-block;
      font-size: 24px;
      line-height: 36px;
      max-width: 296px;
      width: 100%;
      img{
        height: 30px;
      }
      span{
        display: none;
      }
    }
  }
  h2:nth-child(2){
    font-weight: normal;
    font-size: 17px;
    color: #FFFFFF;
    margin: 0 0 40px 0;
    @media(max-width: 900px){
      display: none;
    }
  }
  a{
    width: 276px;
    height: 56px;
    background: #4441CF;
    color: #FFFFFF;
    margin: 0 auto;
    text-decoration: none;
    font-weight: bold;
    box-shadow: 0px 7px 14px rgba(68, 65, 207, 0.5);
    border-radius: 8px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    :hover{
      transform: translateY(-2px);
    }
    @media(max-width: 900px){
      max-width: 229px;
      width: 100%;
      height: 40px;
      margin-top: 0;
    }
  }
`

const LandingTextIcons = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  img{
    position: absolute;
  }
  img:nth-child(1){
    top: -130px;
  }
  img:nth-child(2){
    top: -73px;
  }
  img:nth-child(3){
    top: 100px;
  }
  img:nth-child(4){
    top: 273px
  }
  
  ${({ left }) => left && `
    left: 0;
    img:nth-child(1){
      left: 100px;
      filter: drop-shadow(0px 0px 69px #50AF95);
      :hover{
        animation: IconImgAnimation 0.8s linear infinite;
      }
    }
    img:nth-child(2){
      left: -100px;
      filter: drop-shadow(0px 0px 33px #FFFFFF);
      :hover{
        animation: IconImgAnimation 0.8s linear infinite;
      }    
    }
    img:nth-child(3){
      left: -25px;
      filter: drop-shadow(0px 0px 69px #FFFFFF);
      :hover{
        animation: IconImgAnimation 0.8s linear infinite;
      }
    }
    img:nth-child(4){
      left: 90px;
      filter: drop-shadow(0px 0px 69px #006AE3);
      :hover{
        animation: IconImgAnimation 0.8s linear infinite;
      }
    }
  `}
  
  ${({ right }) => right && `
    right: 0;
    img:nth-child(1){
      right: 100px;
      filter: drop-shadow(0px 0px 69px #F5AC37);
      :hover{
        animation: IconImgAnimation 0.8s linear infinite;
      }
    }
    img:nth-child(2){
      right: -100px;  
      top: -73px;
      filter: drop-shadow(0px 0px 69px #F9FAFB);
      :hover{
        animation: IconImgAnimation 0.8s linear infinite;
      }    
    }
    img:nth-child(3){
      right: -25px;
      filter: drop-shadow(0px 0px 69px #0510FF);
      :hover{
        animation: IconImgAnimation 0.8s linear infinite;
      }
    }
    img:nth-child(4){
      right: 90px;
      filter: drop-shadow(0px 0px 69px #8247E5);
      :hover{
        animation: IconImgAnimation 0.8s linear infinite;
      }
    }
  `}
  @media(max-width: 900px){
    img{
      animation: unset !important;145px
    }
  }
  ${({ leftMobile }) => leftMobile && `
  left: 0;
  img:nth-child(1){
    left: 45px;
    width: 56px;
    top: -145px;
    filter: drop-shadow(0px 0px 69px #50AF95);
  }
  img:nth-child(2){
    left: -36px;
    top: -45px;
    width: 65px;
    filter: drop-shadow(0px 0px 33px #FFFFFF);
  }
  img:nth-child(3){
    left: -55px;
    top: 105px;
    width: 72px;
    filter: drop-shadow(0px 0px 69px #FFFFFF);
  }
  img:nth-child(4){
    left: 55px;
    top: 240px;
    width: 59px;
    filter: drop-shadow(0px 0px 69px #006AE3);
  }
`}

${({ rightMobile }) => rightMobile && `
  right: 0;
  img:nth-child(1){
    right: 94px;
    top: -108px;
    width: 65px;
    filter: drop-shadow(0px 0px 69px #F5AC37);
  }
  img:nth-child(2){
    right: -40px;
    top: -50px;
    width: 77px;
    filter: drop-shadow(0px 0px 69px #F9FAFB);
  }
  img:nth-child(3){
    right: -43px;
    top: 105px;
    width: 72px;
    filter: drop-shadow(0px 0px 69px #0510FF);
  }
  img:nth-child(4){
    right: 73px;
    top: 190px;
    width: 72px;
    filter: drop-shadow(0px 0px 69px #8247E5);
  }
`}
`



const NavContainer = styled.div`
  z-index: 1;
  margin: ${props => props.border ? "5vh 0 0 0" : "5vh 0"};
  display: flex;
  border-bottom: ${props => props.border ? "2px solid rgba(233, 233, 252, 0.3)" : ""};
  padding: ${props => props.border ? "0 0 15px 0" : ""};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 900px){
    
  }
`;
const NavIcon = styled.div`
  display: none;
  margin-left: auto;
  margin-top: -5px;
  z-index: 12;
  right: 0;
  & > span{
    height: 2px;
    margin: 4px 0;
    display: block;
    background: #ffffff;
    width: 23px;
    transition: 300ms;
    ${({ landing, color }) => !landing && `
      background: ${color};
    `}
  }

  ${({ NavbarIsActive }) => NavbarIsActive && `
    span:nth-child(1) {
      transform: rotate(-135deg);
      position: relative;
      top: 5px;
    }
    
    span:nth-child(2) {
      display: none;
    }
    
    span:nth-child(3) {
      transform: rotate(135deg);
      position: relative;
      top: -1px;
    }
  `}
  
  
  @media(max-width: 900px){
    display: block;
    margin-left: unset;
  }
`

const NavBrand = styled.a`
  margin-bottom: 0;
  z-index: 12;
  img{
    height: ${props => props.path ? '25px' : '31px'};
  }
  @media(max-width: 900px){
    img{
      height: 23px;
    }
  }
  
`;

const NavLinks = styled.div`
  // width: 393px;
  // width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: row;
  justify-content: space-between; */
  @media(max-width: 900px){
    flex-basis: 100%;
    flex-direction: column;
    position: relative;
    top: -100px;
    transform: translateY(50%);
    margin-top: 15px;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition-property: height, opacity, padding;
    transition-duration: 300ms, 100ms, 300ms;
    transition-timing-function: ease;
    padding: 0 0;
    ${({ active }) => active && `
      overflow: visible;
      height: auto !important;
      padding: 12px 0;
      opacity: 1;
      transition-property: height, opacity;
      transition-duration: 300ms, 100ms;
      transition-timing-function: ease-in;
  `}
  }
`;


const NavItem = styled.a`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-decoration: none;
  margin-left: 31px;
  // color: ${props => props.stake ? (props.theme === 'dark' ? "#4441CF" : "#FFFFFF") : props.color} !important;
  :hover{
    color: #4441CF;
  }
  ${({ launch }) => launch && `
    width: 134px;
    height: 36px;
    text-transform: uppercase;
    background: #4441CF;
    box-shadow: 0px 7px 14px rgba(68, 65, 207, 0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    :hover{
      transform: translateY(-2px);
      color: #FFFFFF;
    }
  `}
  @media(max-width: 900px){
    margin-top: 15px;
    text-align: center;
  }
`;
const Footnote = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  bottom: 0;
  margin: 8vh 10px 20px 10px;
  color: rgba(255, 255, 255, 0.5);
  @media(max-width: 900px){
    flex-direction: column;
    align-items: center;
  }
`
const SocialWrapper = styled.div`
  position: relative;
`
const Social = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > a{
    margin-left: 14px;
    img{
      height: 20px;
    }
    &:first-child{
      margin-left: 0;
    }
  }
  & > a:nth-child(2){
    img{
      height: 24px;
    }
  }
  @media(max-width: 900px){
    margin: 15px 0 0 0;
  }
`
const ProtocolsPartnersWrapper = styled.div`
  display: flex;
  z-index: 1;
`
const ProtocolsWrapper = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
  }
`
const Protocols = styled.div`
  display: flex;
  width: 100%;
  a{
    background: #FFFFFF;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    img{
      height: 20px;
    }
  }
  @media(max-width: 900px){
    justify-content: center;
  }
`

const PartnersWrapper = styled.div`
  margin-left: 50px;
  h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
  }
`

const Partners = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  a{
    background: #FFFFFF;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    img{
      height: 20px;
    }
  }
`
const CardContentWrapper = styled.div`
  width: 94%;
`
const CarouselContentWrapper = styled.div`
  position: relative;
`
const CustomSliderWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -74px;
  // bottom: -60px;
  /* padding: 0 20px; */
  & > input[type="range"]{
    width: 100%;
    transition: 300ms;
  }
  & > input[type="range"]::-webkit-slider-runnable-track{
    background: ${props => props.slider};
    transition: 300ms;
  }
  & > input[type="range"]::-webkit-slider-thumb {
    background: ${props => props.color};
    transition: 300ms;
  }
`


const CarouselHeaderTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: ${props => props.color};
`
const AnalyticsCardTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  height: 21px;
  color: ${props => props.color};
`

const AnalyticsCardWrapper = styled.div`
  max-width: 396px;
  height: 184.84px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background: ${props => props.color};
  /* border: 2px solid #4441CF; */
  box-sizing: border-box;
  box-shadow: 0px 2px 9px rgba(68, 65, 207, 0.2);
  @media (max-width:1024px) and (min-width:991px) {
    max-width: 430px;
  }
  
`


const StyledSlider = styled(ReactSlider)`
    height: 5px;
    transition: 300ms;
    width: 100%;
    background: ${props => props.slider};
`;

const StyledThumb = styled.div`
  // -webkit-appearance: none;
  border: none;
  height: 5px;
  width: 15%;
  /* position: absolute; */
  /* border-radius: 0%; */
  border-radius: 100px;
  top: 50%;
  transform: translateY(80%);
  transition: 300ms;
  margin-top: -4px;
  top: 0;
  outline: none;
  z-index: 100;
  background: ${props => props.color};

`;

const StyledTrack = styled.div`
  width: 300px;
  height: 5px;
  border: none;
  border-radius: 3px;
  transition: 300ms;
`;
const ScrollIconWrapper = styled.div`
  width: 40px;
  height: 60px;
  position: relative;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 54px;
  display: flex;
  justify-content: center;
  margin: 110px auto 0 auto;
  cursor: pointer;
  @media(max-width: 900px){
    display: none;
  }
`;
const scrolIcon = keyframes`
  0%   { transform: translateY(-5px); opacity: 1; }
  25%  { opacity: 1; }
  75%  { transform: translateY(5px); opacity: 0; }
  100% { transform: translateY(0); opacity: 0; }
`;

const ScrollIconWheel = styled.div`
  height: 14px;
  width: 2px;
  background: #FFFFFF;
  position: absolute;
  bottom: 12px;
  border-radius: 54px;
  animation: ${scrolIcon} 1s ease-out infinite;
`;

const WhatIsTranche = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.8;
  width: 91%;
  margin: 220px auto 90px auto;
  @media(max-width: 900px){
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }
  
`
const WhyTrancheWrapper = styled.div`
  
`
const WhyTrancheTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 35px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.8;
  margin: 0 auto 40px auto;
`
const WhyTrancheContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const WhyTrancheCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 100px auto;
  h2{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    margin-left: 12px;
    max-width: 315px;
  }
`
const LandingTableWrapper = styled.div`
  background-color: rgba(68, 65, 207, 0.15) !important;
  // backdrop-filter: blur(20px);
  box-sizing: content-box;
  border-radius: 24px;
  background: url(${props => props.border});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto 100%;
  margin: 0 0 107px 0;
  padding: 32px;
  @media(max-width: 900px){
    padding: 16px;
  }
`



const WhyTrancheHeader = styled.div`
  margin: 0 0 40px 0;
`
const LandingTableHeader = styled.div`
  margin: 0 0 40px 0;
  @media(max-width: 900px){
    margin: 0;
  }
`
const LandingTableTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  background: -webkit-linear-gradient(#676767, #EBEBEB);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  margin: 0;
  @media(max-width: 900px){
    font-size: 16px;
  }
`
const LandingTableContent = styled.div`

`
const LandingTableHead = styled.div`
  display: flex;
  justify-content: space-between;
`
const LandingTableCards = styled.div`

`
const LandingTableCardMobileHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
`
const LandingTableCardMobileCrypto = styled.div`
  display: flex;
  align-items: center;
  img{
    margin-right: 12px;
    width: 24px;
  }
  h2{
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
  }
`
const LandingTableCardMobileProtocol = styled.div`
  display: flex;
  align-items: center;
  img{
    height: 16px;
    margin-right: 9px;
  }
  h2{
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
  }
`
const LandingTableCardMobileContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const LandingTableCardMobileContentCol = styled.div`
  display: flex;
  align-items: baseline;
`
const LandingTableCardMobileContentColTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  margin: 0;
`
const LandingTableCardMobileContentColValue = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  margin: 8px 0;
  span{
    margin: 0 8px;
  }
`
const LandingTableCardMobileContentColSubValue = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  margin: 0;
`

const LandingTableCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  padding: 40px 0;
  ${({ noBorder }) => noBorder && `
    border: none;
  `}
  @media(max-width: 992px){
    padding: 16px 0;
    flex-direction: column;
  }
`
const LandingTableCol = styled.div`
  width: ${props => props.width};
`
const LandingTableHeadText = styled.h2`
  font-family: Inter, sans-serif;
  border-bottom: 2px dotted rgba(255, 255, 255, 0.3);
  display: initial;
  cursor: default;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
`

const LandingTableColContent = styled.div`
  ${({ first }) => first && `
    display: flex;
    align-items: center;
    img{
      width: 40px;
    }
    div{
      margin-left: 16px;
      h2{
        font-family: 'Inter', sans-serif;
        margin: 5px auto;
      }
      h2:first-child{
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
        color: #FFFFFF;
        span{
          text-transform: lowercase !important;
        }
      } 
      h2:last-child{
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: rgba(255, 255, 255, 0.6);
        span{
          text-transform: lowercase !important;
        }
      }
    }
    
  `}
  ${({ second }) => second && `
    h2{
      font-family: 'Inter', sans-serif;
      margin: 5px auto;
    }
    h2:first-child{
      display: flex;
      align-items: center;
      span{
        font-weight: bold;
        font-size: 17px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    } 
    h2:last-child{
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      letter-spacing: 0.03em;
      color: rgba(255, 255, 255, 0.6);
    }
  `}
  ${({ third }) => third && `
      div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 146px;
        height: 34px;
        border: 2px solid #4441CF;
        box-sizing: border-box;
        border-radius: 4px;
        h2{
          font-family: 'Inter', sans-serif;
          margin: 0 0 0 10px;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.03em;
          color: #FFFFFF;
        }
        img{
          height: 16px;
        }
      }
  `}
  ${({ fourth }) => fourth && `
    h2{
      font-family: 'Inter', sans-serif;
      margin: 5px auto;
    }
    h2:first-child{
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.03em;
      color: #FFFFFF;
    } 
    h2:last-child{
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.6);
    }    
  `}
  ${({ fifth }) => fifth && `
    display: flex;
    justify-content: end;
    a{
      width: 190px;
      height: 42px;
      background: #151541;
      border-radius: 8px;
      font-family: 'Inter', sans-serif;
      font-weight: bold;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      color: #A09EFF;
      span{
        text-transform: lowercase !important;
      }
      img{
        margin-left: 10px;
        transition: 300ms;
      }
      :hover{
        img{
          transform: translateX(6px);
        }
      }
    }
  `}
`

const TrancheIntegrationsWrapper = styled.div`
  margin-bottom: 120px;
`
const TrancheIntegrationsTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  margin-bottom: 31px;
  @media(max-width: 900px){
    font-size: 18px;
  }
`
const TrancheIntegrationsCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 992px){
    flex-direction: column;
  }
`
const TrancheIntegrationsCard = styled.div`
  padding: 24px 24px 19px 24px;
  box-sizing: border-box;
  background: linear-gradient(95.64deg, #4441CF -117.13%, rgba(68, 65, 207, 0.08) 89.73%), url(${props => props.border}) !important;
  background-repeat: no-repeat !important;
  background-position: top left;
  background-size: auto 100%;
  border-radius: 16px;
  height: 131px;
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media(max-width: 992px){
    width: 100%;
    margin-bottom: 30px;
    height: 156px;
  }
`
const TrancheIntegrationsText = styled.div`
  width: 26%;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.02em;
  background: -webkit-linear-gradient(#4441CF, rgba(68, 65, 207, 0.5));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: right;
  @media(max-width: 992px){
    width: 100%;
    font-size: 18px;
    text-align: center;
  }
`

const TrancheIntegrationsCardTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  @media(max-width: 900px){
    font-size: 18px;
  }
`
const TrancheIntegrationsCardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 900px){
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
const TrancheIntegrationsLogo = styled.div`
  display: flex;
  align-items: center;
  img{
    width: ${props => props.imgWidth ? props.imgWidth : ""};
  }
  h2{
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-left: 12px;
  }
  @media(max-width: 900px){
    // margin: 0 15px 0 0;
    width: 50%;
    img{
      height: 25px;
      width: auto;
    }
    h2{
      margin-left: 8px;
    }
  }
`
const SecurityAuditWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 128px;
  justify-content: space-between;
  @media(max-width: 900px){
    flex-direction: column-reverse;
  }
`
const SecurityAuditSection = styled.div`
  width: ${props => props.width};
  ${({ right }) => right && `
    display: flex;
    justify-content: flex-end;
  `}
  @media(max-width: 900px){
  ${({ right }) => right && `
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 100%;
      margin: 0 0 40px 0;
    }
  `}
  width: 100%;
  }
`


const SecurityAuditSectionTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 28px;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  @media(max-width: 900px){
    font-size: 20px;
    text-align: center;
  }
`
const SecurityAuditSectionText = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 19px;
  line-height: 28.5px;
  color: #FFFFFF;
  margin: 16px 0 24px 0;
  @media(max-width: 900px){
    font-size: 14px;
    line-height: 20px;
    margin: 12px 0 24px 0;
    text-align: center;
  }
`


const LandingLinkTab = styled.a`
  width: 296px;
  height: 56px;
  background: #151541;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #A09EFF;
  img{
    margin-left: 10px;
    width: 15px;
    transition: 300ms;
  }
  :hover{
    img{
      transform: translateX(6px);
    }
  }
  span{
    text-transform: lowercase !important;
  }
  @media(max-width: 900px){
    width: ${props => props.width ? props.width : "100%"};
    height: ${props => props.height ? props.height : "48px"};
    font-size: ${props => props.fontSize ? props.fontSize : "14px"};
    img{
      width: ${props => props.imgWidth}
    }
    margin: ${props => props.margin};
  }
`

const InvestorsWrapper = styled.div`
  margin: 0 0 42px 0;
`
const InvestorsTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 35px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.8;
  margin: 0;
  @media(max-width: 900px){
    font-size: 20px;
    text-align: center;
  }
`
const InvestorsText = styled.div`
  margin: 24px auto 32px auto;
  h2{
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0;
    color: #FFFFFF;
  }
  h2:last-child{
    margin: 0 auto;
    width: 73.6%;
  }
  @media(max-width: 900px){
    margin: 12px auto 32px auto;
    h2{
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      width: 100% !important;
    }
  }
`
const InvestorsForm = styled.form`
  max-width: 642px;
  margin: 0 auto;
`
const InvestorsFormGrp = styled.div`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input{
    max-width: 312px;
    width: 100%;
    height: 56px;
    background: #212121;
    border: 1.5px solid rgba(255, 255, 255, 0.16);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: rgba(255, 255, 255, 0.4);
    outline: none;
    ::-webkit-input-placeholder{
      font-family: 'Inter', sans-serif;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: rgba(255, 255, 255, 0.4);
    }
    
    :-ms-input-placeholder{
      font-family: 'Inter', sans-serif;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: rgba(255, 255, 255, 0.4);
    }
    
    ::placeholder{
      font-family: 'Inter', sans-serif;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.03em;
      color: rgba(255, 255, 255, 0.4);
    }
    @media(max-width: 900px){
    }
  }
  @media(max-width: 900px){
    flex-direction: column;
    input{
      margin: 0 0 16px 0;
      width: 100%;
      max-width: unset;
    }
  }
`
const InvestorsFormSubmit = styled.button`
  font-family: 'Inter', sans-serif;
  width: 100%;
  height: 56px;
  background: ${props => props.color ? props.color : "#4441CF"};
  border-radius: 8px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.cursor ? props.cursor : "pointer"};
  border: none;
  outline: none;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 23px 0 0 0;
  img{
    margin-right: 8px;
  }
  ${({ disabled }) => disabled && `
    background-color: #cccccc;
    color: #666666;
    pointer-events: default;
  `}
  @media(max-width: 900px){
    margin: 16px 0 0 0;
    ${({ formSubmitted }) => formSubmitted && `
      font-size: 10px
    `}
  }
`
const FooterWrapper = styled.div`
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.35);
  padding: 16px 0;
`
const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 900px){
    flex-direction: column;
  }
`
const FooterLinks = styled.div`
  width: 33.3333333%;
  a{
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.03em;
    margin-right: 25px;
    color: #FFFFFF;
    text-decoration: none;
    :hover{
      color: #4441CF;
    }
  }
  @media(max-width: 900px){
    width: 100%;
  }
`
const FooterText = styled.h2`
  width: 33.3333333%;
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  text-decoration: none;
  text-align: center;
  @media(max-width: 900px){
    display: none;
  }
`
const FooterSocial = styled.div`
  width: 33.3333333%;
  display: flex;
  align-items: center;
  justify-content: end;
  a{
    margin-left: 27px;
  }
  @media(max-width: 900px){
    width: 100%;
    justify-content: flex-start;
    margin: 16px 0 0 0;
    a{
      margin-left: 0;
      margin-right: 27px;
    }
  }
`
const WhyTrancheCardWrapper = styled.div`
  width: 95% !important;
  height: 220px;
  margin-bottom: 56px;
  background: #4441CF;
  border-radius: 16px;
  margin-right: 18px;
  box-sizing: border-box;
  padding: 16px;
  h2{
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
  }
`
const NavMobileWrapper = styled.div`
  width: 100%;
  max-height: 233px;
  padding: 0 0 20px 0;
  background: linear-gradient(141.1deg, #000000 0%, #4441CF 101.18%);
  border-radius: 0px 0px 16px 16px;
  z-index: 10;
  position: absolute;
  transition: 300ms;
  transform: translateY(-120%);
  visibility: hidden;
  top: 0;
  left: 0;
  ${({ NavbarIsActive }) => NavbarIsActive && `
    transform: translateY(0%);
    visibility: visible;
  `}
`
  
const NavMobileContent = styled.div`
  margin: 73px 0 0 15px;
  a{
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.03em;
    text-decoration: none;
    margin: 12px 0 0 0;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
  }
`
  

export{
    LandingTextWrapper,
    LandingLogo,
    LandingTextContent,
    Container,
    LandingWrapper,
    LandingTextIcons,
    LandingContent,
    NavContainer,
    NavIcon,
    NavBrand,
    NavLinks,
    NavItem,
    Footnote,
    SocialWrapper,
    Social,
    ProtocolsWrapper,
    Protocols,
    Partners,
    ProtocolsPartnersWrapper,
    PartnersWrapper,
    LayoutWrapper,
    Background,
    BackgroundSky,
    BackgroundSkyStars,
    BackgroundSkyStar,
    BackgroundGround,
    BackgroundLinesWrapper,
    BackgroundLines,
    BackgroundVerticalLines,
    BackgroundVerticalLine,
    BackgroundHorizontalLines,
    BackgroundHorizontalLine,
    CardContentWrapper,
    BackgroundShadow,
    LayoutContent,
    CarouselHeaderTitle,
    AnalyticsCardTitle,
    AnalyticsCardWrapper,
    CarouselContentWrapper,
    CustomSliderWrapper,
    StyledSlider,
    StyledTrack,
    StyledThumb,
    ScrollIconWrapper, 
    ScrollIconWheel,
    WhatIsTranche,
    WhyTrancheWrapper,
    WhyTrancheHeader,
    WhyTrancheTitle,
    WhyTrancheContent,
    WhyTrancheCard,
    LandingTableWrapper,
    LandingTableHeader,
    LandingTableTitle,
    LandingTableContent,
    LandingTableHead,
    LandingTableCards,
    LandingTableCard,
    LandingTableCol,
    LandingTableHeadText,
    LandingTableColContent,
    TrancheIntegrationsWrapper,
    TrancheIntegrationsTitle,
    TrancheIntegrationsCards,
    TrancheIntegrationsCard,
    TrancheIntegrationsCardTitle,
    TrancheIntegrationsCardContent,
    TrancheIntegrationsText,
    TrancheIntegrationsLogo,
    SecurityAuditWrapper,
    SecurityAuditSection,
    LandingLinkTab,
    SecurityAuditSectionTitle,
    SecurityAuditSectionText,
    InvestorsTitle,
    InvestorsText,
    InvestorsForm,
    InvestorsFormGrp,
    InvestorsFormSubmit,
    InvestorsWrapper,
    FooterWrapper,
    FooterContent,
    FooterLinks,
    FooterText,
    FooterSocial,
    WhyTrancheCardWrapper,
    LandingTableCardMobileHeader,
    LandingTableCardMobileCrypto,
    LandingTableCardMobileProtocol,
    LandingTableCardMobileContent,
    LandingTableCardMobileContentCol,
    LandingTableCardMobileContentColTitle,
    LandingTableCardMobileContentColValue,
    LandingTableCardMobileContentColSubValue,
    NavMobileWrapper,
    NavMobileContent
}