import React from 'react';
import { ScrollIconWrapper, ScrollIconWheel } from '../styled';
function ScrollIcon({ scrollPage }) {
    return (
        <ScrollIconWrapper onClick={scrollPage}>
            <ScrollIconWheel></ScrollIconWheel>
        </ScrollIconWrapper>
   );
}

export default ScrollIcon;