import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`  
  html{
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  body{
    margin: 0;
    background-color: ${props => props.theme.body};
    overflow-x: hidden;
  }
  // .container{
  //   // padding-right: 15px;
  //   // padding-left: 15px;
  //   // margin-right: auto;
  //   // margin-left: auto;
  //   padding-right: 25px;
  //   padding-left: 25px;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
  .content-container{
    padding-right: 15px; 
    padding-left: 15px; 
    margin-right: auto; 
    margin-left: auto; 
  }
  @media(min-width: 768px){ 
    .content-container{ 
        width: 750px; 
    } 
  } 

  @media(min-width: 992px){ 
      .content-container{ 
          width: 970px; 
      } 
  } 

  @media (min-width: 1200px){ 
      .content-container{ 
        width: 1270px;
      } 
  }
  // @media (min-width: 768px){
  //   .container{
  //     width: 770px;
  //   }
  // }
  // @media (min-width: 992px){
  //   .container{
  //     width: 990px;
  //   }
  // }
  // @media (min-width: 1200px){
  //   .container{
  //     width: 1326px;
  //   }
  // }

  .characters{
    animation: TextAnimation 500ms ease-out 1 both;
    display: inline-block;
  }
  @keyframes TextAnimation{
    from{
        opacity: 0;
        transform: perspective(1000px) translate3d(0, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }
    to{
        opacity: 1;
        transform: perspective(1000px) translate3d(0, 0, 0);
    }
  }

  @media only screen and (max-width: 700px) {
    
  }
`;

export { GlobalStyle };
