console.log(process.env.REACT_APP_TRANCHE_APP_URL);
// Nav Links
export const HomeURL = '/';
export const DocsURL = 'https://docs.tranche.finance/';
export const LaunchURL = `${process.env.REACT_APP_TRANCHE_APP_URL}/borrow`;
export const StakeURL = `${process.env.REACT_APP_TRANCHE_APP_URL}/stake`;
export const TrancheURL = `${process.env.REACT_APP_TRANCHE_APP_URL}/tranche`;
export const SLICEURL = 'https://tranchefinance.medium.com/slice-ing-defi-2f032d74fd9e';

// API links 

export const ApiURL = process.env.REACT_APP_TRANCHE_API_URL;

// Social Media Links
export const TwitterURL = 'https://twitter.com/TrancheFinance';
export const MediumURL = 'https://tranchefinance.medium.com/';
export const DiscordURL = 'https://discord.com/invite/Nv44PTdF3K';


