import React from 'react';
import {
    WhatIsTranche
} from '../styled';


function WhatTrancheDoes() {
    return (
     <WhatIsTranche id="WhatTrancheDoes">
        Tranche splits any interest generating asset from the DeFi ecosystem into two new assets; a fixed-rate asset that manages risk, and a variable-rate asset that maximizes returns.
     </WhatIsTranche>   
   );
}

export default WhatTrancheDoes;