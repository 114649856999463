import styled from "styled-components";


const HeaderWrapper = styled.div`

`
const HeaderNavLinks = styled.div`
    margin: 35px 0;
    svg{
        margin: 0 15px;
        opacity: 0.5;
    }
`
const HeaderNavTitle = styled.a`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 19.1306px;
    // display: flex;
    // align-items: center;
    color: ${props => props.color};
    opacity: 0.5;
    text-decoration: none;
    ${({ current, currentColor }) => (current) && `
        opacity: 1;
        color: ${currentColor};
    `}
`
const HeaderTitleTabs = styled.div`
    height: 125px;
    background: ${props => props.color};
    border-radius: 10px;
    padding: 36px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    @media(max-width: 900px){
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`
const HeaderTitleImg = styled.div`
    max-width: 40px;
    margin: -15px 0 0 0;
    div{
        @media(max-width: 900px){
            span{
                left: 20px;
            }
            img{
                height: 29px;
            }
        }
    }
`
const HeaderTitleWrapper = styled.div`
    display: flex;
    align-items: center;
`
const HeaderTitle = styled.h2`
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 22px;
    margin-left: 35px;
    color: ${props => props.color};
    @media(max-width: 900px){
        font-size: 17px;
        margin-left: 24px;
    }
`
const HeaderTabsWrapper = styled.div`
    max-width: 324.63px;
    width: 100%;
    height: 49.71px;
    background: ${props => props.toggleBackground};
    border: 1.01446px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 161.299px;
    position: relative;
    display: flex;
    justify-content: space-between;
    border: 1px solid ${props => props.StakeModalNavigationBorder};
    span{
        position: absolute;
        background: ${props => props.stakeBoxBackground};
        border-radius: 124.567px;
        height: 100%;
        width: 50%;    
        transition: 300ms;
        top: 0;
        left: 0;
        ${({ left }) =>  left && `
            left: 0;
        `}
        ${({ right }) =>  right && `
            left: 100%;
            transform: translateX(-100%);
        `}
        ${({ left, theme }) =>  (left && theme === "light") && `
            border-right: 0.536446px solid #E9E9FC;
        `}
        ${({ right, theme }) =>  (right && theme === "light") && `
            border-left: 0.536446px solid #E9E9FC;
        `}
    }

    ${({ graph }) =>  graph && `
        width: 230px;
        height: 42.75px;
    `}

    

    

    @media(max-width: 900px){
        height: 30.71px;
        max-width: 205px;
        margin: 12px 0;
        width: 50%;
    }
    ${({ firstChart }) =>  firstChart && `
        height: 42.71px;
        max-width: 230px;
        position: absolute;
        left: 234px;
        button{
            font-size: 12.78px;
        }
        @media (max-width: 600px){
            position: relative;
            left: 0;
        }
    `}



    
`
const HeaderTab = styled.button`
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 14.948px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    ${({ active }) => active && `
        opacity: 1;
    `}
    ${({ active }) => !active && `
        transition: 300ms;
        opacity: 0.8;
        :hover{
            opacity: 0.5;
        }
    `}

    ${({ active, theme }) => (!active && theme === "light") && `
        color: #4441CF !important;
        // color: rgba(36,39,50,0.6) !important;
    `}

    color: ${props => props.StakeModalNavigationText};
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    width: 50%;
    ${({ graph }) =>  graph && `
        font-size: 12.948px;
        
    `}
    @media(max-width: 900px){
        font-size: 6.948px !important;
    }
`
const HeaderOverviewWrapper = styled.div`
    & > h2{
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 22px;
        color: ${props => props.color};
        margin: 25px 0;
    }
`

const HeaderOverviewContent = styled.div`
    height: 133px;
    background: ${props => props.color};
    border-radius: 7.55721px;
    margin: 0 0 24px 0;
    padding: 28px 52px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    & > span{
        height: 100%;
        width: 2.1592px;
        background: ${props => props.border};
    }
    @media(max-width: 900px){
        padding: 28px 24px;
        flex-wrap: wrap;
        min-height: 133px;
        height: auto;
        span{
            display: none;
        }
    }
`
const HeaderOverviewContentCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > h2{
        font-family: 'Inter', sans-serif;
        margin: 10px 0;
        width: fit-content;
    }
    & > h2:first-child{
        font-weight: 600;
        font-size: 15.1144px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: ${props => props.color};
        cursor: pointer;
        padding: 3px 0;
        border-bottom: 2px dashed ${props => props.color};
        @media(max-width: 900px){
            font-size: 8.1144px;
        }
        :hover{
            color: ${props => props.theme === "light" ? "#242732" : "#FFFFFF"};
            border-color: ${props => props.theme === "light" ? "#242732" : "#FFFFFF"};
        }


        
    }
    h2:nth-child(2){
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        text-transform: uppercase;
        color: ${props => props.value};
        @media(max-width: 900px){
            font-size: 9px;
        }
    }
    @media(max-width: 900px){
        width: 50%;
        h2:last-child{
            margin: 0;
        }
    }
`
const MarketDetailsWrapper = styled.div`
    height: 621px;
    width: 49%;
    background: ${props => props.color};
    border-radius: 10px;
    margin: 0 0 100px 0;
    @media(max-width: 900px){
        width: 100%;
    }
    
`

const MarketDetails = styled.div`
    display: flex;
    justify-content: space-between;
    @media(max-width: 900px){
        flex-direction: column;
    }
`
const TranchePerformanceText = styled.div`
    display: flex;
    align-items: center;
    & > h2{
        font-family: 'Inter', sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        margin-right: 22px;
    }
    @media(max-width: 900px){    
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > h2{
            margin: 0;
        }
    }
`
const TranchePerformanceToggle = styled.div`
    @media(max-width: 900px){    
        display: flex;
        flex-direction: column;
    }

`

const TranchePerformanceToggleBtn = styled.button`
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 14.948px;
    color: rgba(255, 255, 255);
    cursor: pointer;
    background: transparent;
    outline: none;
    border: none;
    opacity: ${props => props.active ? 1 : 0.2};
`
const TranchePerformanceHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 900px){    
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`
const TranchePerformanceWrapper = styled.div`
    height: 478px;
    background: rgba(233, 233, 252, 0.05);
    border-radius: 10px;
    margin: 0 0 24px 0;
    // padding: 47px;
    box-sizing: border-box;
`
const TranchePerformanceContent = styled.div`
`
const CanvarWrapper = styled.div`
    canvas{
        margin-top: 35px;
    }
`

const MarketDetailsRow = styled.div`
    padding: 0 34px;
    box-sizing: border-box;
    height: 88.5px;
    border-bottom: 2px solid ${props => props.border};
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2{
        font-family: 'Inter', sans-serif;
        margin: 0;
    }
    h2:first-child{
        font-weight: 600;
        font-size: 15.1144px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: ${props => props.color};     
        opacity: ${props => props.theme === "light" ? "0.5": "0.8"};
        @media(max-width: 900px){    
            font-size: 12.1144px;
        }
    }
    h2:last-child{
        font-weight: 600;
        font-size: 15.1144px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: ${props => props.color};
        opacity: ${props => props.theme === "light" ? "1": "0.8"}; 
        @media(max-width: 900px){
            font-size: 12.1144px;
        }
    }
    ${({ title , color}) => (title) &&`
        height: 90px;
        display: flex;
        align-items: center;
        h2{
            font-weight: bold !important;
            font-size: 18px !important;
            color: ${color} !important;
            text-transform: capitalize !important;
        }
    `}
    ${({ last }) => (last) &&`
        border-bottom: none;
    `}
`


export {
    HeaderWrapper,
    HeaderNavLinks,
    HeaderNavTitle,
    HeaderTitleTabs,
    HeaderTitleWrapper,
    HeaderTitle,
    HeaderTitleImg,
    HeaderTabsWrapper,
    HeaderTab,
    HeaderOverviewWrapper,
    HeaderOverviewContent,
    HeaderOverviewContentCol,
    MarketDetailsWrapper,
    MarketDetailsRow,
    MarketDetails,
    TranchePerformanceWrapper,
    TranchePerformanceContent,
    TranchePerformanceToggle,
    TranchePerformanceText,
    TranchePerformanceHeader,
    TranchePerformanceToggleBtn,
    CanvarWrapper
}