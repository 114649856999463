import React, { useEffect, useState } from 'react';
import Tilt from 'react-parallax-tilt';
import { TrancheURL } from 'constants/urls';
import useAnalytics from 'services/analytics';
import {
    LandingTextWrapper,
    LandingTextContent,
    LandingTextIcons
} from '../../styled';

import { ChainlinkLanding, DAILanding, ETHLanding, FantomLanding, LandingAAVE, LandingBitcoin, LandingChainlink, LandingEthereum, LandingPolygon, LandingYearn, LogoColored, PolygonLanding, TetherLanding, UniswapLanding, YearnIconTable, YearnLanding } from 'assets';

function HeaderWrapper() {
    const [isDesktop, setDesktop] = useState(window.innerWidth > 900);
    const updateMedia = () => {
      setDesktop(window.innerWidth > 900);
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    });
    useEffect(() => {
        const removeAnimationId = setTimeout(() => {
            let images = document.querySelectorAll('.headerIconsAnimation');
            for (let i = 0; i < images.length; i++){
                let image = images[i];
                image.setAttribute("id", "");
            }
        }, 1000);
        return () => clearTimeout(removeAnimationId);
    }, []);
    return (
        <LandingTextWrapper>
            {
                isDesktop ?
                    <LandingTextIcons left>
                        <img src={TetherLanding} alt="icon" className="headerIconsAnimation" id="firstHeaderImg" />
                        <img src={ChainlinkLanding} alt="icon" className="headerIconsAnimation" id="secondHeaderImg" />
                        <img src={ETHLanding} alt="icon" className="headerIconsAnimation" id="thirdHeaderImg" />
                        <img src={YearnLanding} alt="icon" className="headerIconsAnimation" id="fourthHeaderImg" />
                    </LandingTextIcons> :
                    <LandingTextIcons leftMobile>
                        <img src={FantomLanding} alt="icon" />
                        <img src={PolygonLanding} alt="icon" />
                        <img src={TetherLanding} alt="icon" />
                        <img src={YearnLanding} alt="icon" />
                    </LandingTextIcons>
            }

            <LandingTextContent>
                <h2>
                    Managing risk &amp; Maximizing return
                </h2>
                <h2>A multi-chain DeFi derivatives platform</h2>
                <a href={TrancheURL} target="_blank">Launch App</a>
            </LandingTextContent>
            {
                isDesktop ?
                <LandingTextIcons right>
                    <img src={DAILanding} alt="icon" className="headerIconsAnimation" id="fifthHeaderImg"/>
                    <img src={UniswapLanding} alt="icon" className="headerIconsAnimation" id="sixthHeaderImg" />
                    <img src={FantomLanding} alt="icon" className="headerIconsAnimation" id="seventhHeaderImg" />
                    <img src={PolygonLanding} alt="icon" className="headerIconsAnimation" id="eighthHeaderImg" />
                </LandingTextIcons> : 
                <LandingTextIcons rightMobile>
                    <img src={DAILanding} alt="icon" />
                    <img src={UniswapLanding} alt="icon" />
                    <img src={ChainlinkLanding} alt="icon" />
                    <img src={ETHLanding} alt="icon" />
                </LandingTextIcons>
            }
        </LandingTextWrapper>
   );
}

export default HeaderWrapper;