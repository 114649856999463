import React, { useEffect, useState } from 'react';
import { 
    MarketDetailsWrapper,
    MarketDetailsRow,
    MarketDetails,
} from './styles/AnalyticsComponents';
import DepositsChart from './Charts/DepositsChart';
import * as d3 from 'd3';
import { ModeThemes } from 'constants/constants';
import { roundNumber } from 'utils/helperFunctions';

const chart2currentSIRlocation = .5;
const dimensionsChart2 = {
  width: 629,
  height: 621,
  margin: { top: 50, right: 45, bottom: 90, left: 75 },
  header: 160
};


const labelsChart2 = {
  mainTitle: 'Relationship between Tranche Deposits and SLICE Rewards',
  upperLabel: 'SOLVENCY INCENTIVE RATIO',
  leftLabel: 'SLICE REWARDS',
  lowerLabel: 'Deposits in Tranche B (USD)',
  lowerSubLabel: 'Tranch A Deposit [Fixed]',
  current: 'Current',
  trancheA: 'Tranche A',
  trancheB: 'Tranche B',
  annotation: ''
};



function MarketDetailsComponent ({ theme, trancheSummary, trancheType, chartData }) {
  const [generatedDataTrancheA, setGeneratedDataTrancheA] = useState([]);
  const [generatedDataTrancheB, setGeneratedDataTrancheB] = useState([]);
  const [chart2currentSIR, setChart2currentSIR ] = useState([]);
  
  useEffect(() => {
    setChart2currentSIR(trancheSummary && trancheSummary.sir);
    let generatedTrancheA = d3
      .range(chartData && chartData.length)
      .map(d => 1 / (d * 0.01 + 0.01) + 4)
    let generatedTrancheB = d3
      .range(chartData && chartData.length)
      .map(d => -1 / (d * 0.01 + 0.01) - 4)
      setGeneratedDataTrancheA(generatedTrancheA);
      setGeneratedDataTrancheB(generatedTrancheB);
  }, [chartData, trancheSummary])
  return (
    <MarketDetails>
        <MarketDetailsWrapper color={ModeThemes[theme].AnalyticsHeaderBackground}>
        <div className={`mainPage ${theme}`}>
          {chartData &&
            <DepositsChart
              data={[chartData && chartData.map(d => d.trASlice), chartData && chartData.map(d => -d.trBSlice), generatedDataTrancheA, generatedDataTrancheB, chart2currentSIR, chart2currentSIRlocation]}
              dimensions={dimensionsChart2}
              labels={labelsChart2}
            />
          }
        </div>
        </MarketDetailsWrapper>
        <MarketDetailsWrapper color={ModeThemes[theme].AnalyticsHeaderBackground}>
            <MarketDetailsRow title color={ModeThemes[theme].HeaderTitle} border={ModeThemes[theme].ModalTrancheTextRowBorder}>
                <h2>Market Details</h2>
            </MarketDetailsRow>
            <MarketDetailsRow border={ModeThemes[theme].ModalTrancheTextRowBorder} theme={theme} color={ModeThemes[theme].HeaderTitle}>
                <h2>TOTAL VALUE LOCKED</h2>
                  <h2>${(
                        trancheSummary
                        && trancheSummary
                        && roundNumber((trancheSummary.TRANCHE_A.trancheValueUSD || 0) + (trancheSummary.TRANCHE_B.trancheValueUSD || 0))
                        )
                      || 0}
                  </h2>
            </MarketDetailsRow>
            <MarketDetailsRow border={ModeThemes[theme].ModalTrancheTextRowBorder} theme={theme} color={ModeThemes[theme].HeaderTitle}>
                <h2>PARTICIPANTS</h2>
                  <h2>
                  {(
                        trancheSummary
                        && trancheSummary.totalUsers
                        )
                      || 0}
                </h2>
            </MarketDetailsRow>
            <MarketDetailsRow border={ModeThemes[theme].ModalTrancheTextRowBorder} theme={theme} color={ModeThemes[theme].HeaderTitle}>
                <h2>BALANCE FACTOR</h2>
                <h2>{(
                        trancheSummary
                        && (trancheSummary.balanceFactor || 0) * 100
                        )
                      || 0}%</h2>
            </MarketDetailsRow>
            <MarketDetailsRow border={ModeThemes[theme].ModalTrancheTextRowBorder} theme={theme} color={ModeThemes[theme].HeaderTitle}>
                <h2>SOLVENCY INCENTIVE RATIO (SIR)</h2>
                <h2>{(
                        trancheSummary
                        && (trancheSummary.sir || 0) * 100
                        )
                      || 0}%</h2>
            </MarketDetailsRow>
            <MarketDetailsRow border={ModeThemes[theme].ModalTrancheTextRowBorder} theme={theme} color={ModeThemes[theme].HeaderTitle}>
                <h2>TRANCHE {trancheType? 'A':'B'} SLICE PER DAY</h2>
                  <h2>{
                      (
                        trancheSummary
                        && trancheSummary.TRANCHE_B
                        && roundNumber(trancheSummary[trancheType? 'TRANCHE_A':'TRANCHE_B'].rewardRate || 0)
                        ) || 0
                      } SLICE</h2>
            </MarketDetailsRow>
            <MarketDetailsRow last color={ModeThemes[theme].HeaderTitle} theme={theme}>
                <h2>SLICE REWARDS APY</h2>
                  <h2>{
                      (
                        trancheSummary
                        && trancheSummary[ trancheType? 'TRANCHE_A':'TRANCHE_B' ]
                        && roundNumber(trancheSummary[ trancheType? 'TRANCHE_A':'TRANCHE_B' ].sliceApy || 0)
                        ) || 0
                  }%</h2>
            </MarketDetailsRow>
        </MarketDetailsWrapper>
        
    </MarketDetails>
  );
}

export default MarketDetailsComponent;