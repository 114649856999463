import React, { useState, useEffect } from 'react';
import { 
    HeaderWrapper,
    HeaderNavLinks,
    HeaderNavTitle,
    HeaderTitleTabs,
    HeaderTitleWrapper,
    HeaderTitle,
    HeaderTitleImg,
    HeaderTabsWrapper,
    HeaderTab,
    HeaderOverviewWrapper,
    HeaderOverviewContent,
    HeaderOverviewContentCol,
    TranchePerformanceWrapper,
    TranchePerformanceContent} from './styles/AnalyticsComponents';
import { TooltipWrapper } from '../Table/styles/TableComponents'
import { changeTranche } from '../../../redux/actions/trancheType';
import { connect } from 'react-redux';
import { 
    TableCardImg,
    LoadingContent
} from '../Table/styles/TableComponents';
import { ModeThemes, trancheIcons } from 'constants/constants';
import { roundNumber } from 'utils/helperFunctions';
import PerformanceChart from './Charts/PerformanceChart';
import MarketDetailsComponent from './MarketDetails';


function Header({ theme, market, changeTranche, trancheType }) {
    const [TooltipToggle, setTooltipToggle] = useState("")
    const tooltipToggle = (val) => {
        setTooltipToggle(val);
    }
  return (
    <HeaderWrapper>
        <HeaderNavLinks>
            <HeaderNavTitle color={ModeThemes[theme].HeaderTitle}>
                Tranche Markets
            </HeaderNavTitle>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.05096 6.64853L2.0302 0.627882C1.89095 0.488519 1.70506 0.411743 1.50685 0.411743C1.30864 0.411743 1.12275 0.488519 0.983495 0.627882L0.540108 1.07116C0.251593 1.36 0.251593 1.82946 0.540108 2.11787L5.59589 7.17364L0.534498 12.235C0.395245 12.3744 0.318359 12.5602 0.318359 12.7583C0.318359 12.9566 0.395245 13.1424 0.534498 13.2818L0.977885 13.725C1.11725 13.8644 1.30303 13.9412 1.50124 13.9412C1.69945 13.9412 1.88534 13.8644 2.02459 13.725L8.05096 7.69887C8.19054 7.55906 8.26721 7.3724 8.26677 7.17397C8.26721 6.97477 8.19054 6.78822 8.05096 6.64853Z" fill={ModeThemes[theme].HeaderTitle}/>
            </svg>
            <HeaderNavTitle current currentColor={ModeThemes[theme].languageToggleText} >
                {market.trancheSummary && ((trancheType ? 'a' : 'b') + market.trancheSummary.dividendType)}
            </HeaderNavTitle>
        </HeaderNavLinks>
        <HeaderTitleTabs color={ModeThemes[theme].AnalyticsHeaderBackground}>
            {   market.trancheSummary ?
                <HeaderTitleWrapper>
                    <HeaderTitleImg>
                        <TableCardImg
                            tranche={true}
                        >
                            <img src={trancheIcons[market.trancheSummary && market.trancheSummary.dividendType] && trancheIcons[market.trancheSummary && market.trancheSummary.dividendType].protocolIcon} alt='ProtocolIcon' />
                            <span>
                                <img src={trancheIcons[market.trancheSummary &&  market.trancheSummary.dividendType] && trancheIcons[market.trancheSummary && market.trancheSummary.dividendType].assetIcon} alt='AssetIcon' />
                            </span>
                        </TableCardImg>
                    </HeaderTitleImg>
                    <HeaderTitle color={ModeThemes[theme].HeaderTitle}>
                        {market.trancheSummary && ((trancheType ? 'a' : 'b') + market.trancheSummary.dividendType)}
                    </HeaderTitle>
                </HeaderTitleWrapper> :
                <div className='loadingFirstCol'>
                    <div className='loadingFirslColContent'>
                    <LoadingContent className='loadingAvatar loadingContent ' colorOne={ModeThemes[theme].LoadingColorOne} colorTwo={ModeThemes[theme].LoadingColorTwo}></LoadingContent>
                    <LoadingContent className='loadingText loadingContentWrapper loadingContent' colorOne={ModeThemes[theme].LoadingColorOne} colorTwo={ModeThemes[theme].LoadingColorTwo}></LoadingContent>
                    </div>
                </div>
            }
            
            <HeaderTabsWrapper
                stakeBoxBackground={ModeThemes[theme].stakeBoxBackground}
                stakeModalBoxBackground={ModeThemes[theme].stakeModalBoxBackground}
                StakeModalNavigationBorder={ModeThemes[theme].StakeModalNavigationBorder}
                toggleBackground={ModeThemes[theme].toggleBackground}
                theme={theme}
                tranche={trancheType}
                left={trancheType}
                right={!trancheType}
            >
                <HeaderTab
                    stakeModalBoxShadow={ModeThemes[theme].stakeModalBoxShadow}
                    stakeModalBoxBackground={ModeThemes[theme].stakeModalBoxBackground}
                    StakeModalNavigationText={ModeThemes[theme].StakeModalNavigationText}
                    theme={theme}
                    active={trancheType}
                    onClick={() => changeTranche(true)}
                >
                    Tranche A
                </HeaderTab>
                <span></span>
                <HeaderTab
                    stakeModalBoxShadow={ModeThemes[theme].stakeModalBoxShadow}
                    stakeModalBoxBackground={ModeThemes[theme].stakeModalBoxBackground}
                    StakeModalNavigationText={ModeThemes[theme].StakeModalNavigationText}
                    theme={theme}
                    active={!trancheType}
                    onClick={() => changeTranche(false)}
                >
                    Tranche B
                </HeaderTab>
            </HeaderTabsWrapper>
        </HeaderTitleTabs>

        <HeaderOverviewWrapper color={ModeThemes[theme].HeaderTitle}> 
            <h2>Tranche {trancheType ? 'A' : 'B'} Overview</h2>
            <HeaderOverviewContent color={ModeThemes[theme].AnalyticsHeaderBackground} border={ModeThemes[theme].dropDownBorder}>
                <HeaderOverviewContentCol theme={theme} color={ModeThemes[theme].TableHead} value={ModeThemes[theme].HeaderTitle}>
                    <h2 onMouseOver={() => tooltipToggle("PRICE")} onMouseLeave={() => tooltipToggle("")}>PRICE</h2>
                    <h2>{roundNumber(market.trancheSummary && market.trancheSummary[trancheType ? 'TRANCHE_A' : 'TRANCHE_B'].trancheRate)}  {market.trancheSummary && market.trancheSummary.cryptoType}</h2>
                    <TooltipWrapper PRICE tooltip={TooltipToggle === "PRICE"} platform color={ModeThemes[theme].Tooltip} language={'en'}>
                        <div>
                            <h2> The exchange rate between the deposited asset and the tranched asset price</h2>
                        </div>
                    </TooltipWrapper>
                </HeaderOverviewContentCol>
                <span></span>
                <HeaderOverviewContentCol theme={theme} color={ModeThemes[theme].TableHead} value={ModeThemes[theme].HeaderTitle}>
                    <h2 onMouseOver={() => tooltipToggle("TRANCHEAPY")} onMouseLeave={() => tooltipToggle("")}>TRANCHE APY</h2>
                    <h2>{roundNumber(market.trancheSummary && market.trancheSummary[trancheType ? 'TRANCHE_A' : 'TRANCHE_B'].apy)}%</h2>
                    <TooltipWrapper TRANCHEAPY tooltip={TooltipToggle === "TRANCHEAPY"} platform color={ModeThemes[theme].Tooltip} language={'en'}>
                        <div>
                            <h2> Annualized return of the tranche, paid in the underlying asset. Tranche A APYs are fixed and B APYs are variable</h2>
                        </div>
                    </TooltipWrapper>
                </HeaderOverviewContentCol>
                <span></span>
                <HeaderOverviewContentCol theme={theme} color={ModeThemes[theme].TableHead} value={ModeThemes[theme].HeaderTitle}>
                    <h2 onMouseOver={() => tooltipToggle("SLICEAPY")} onMouseLeave={() => tooltipToggle("")}>SLICE APY</h2>
                    <h2>{roundNumber(market.trancheSummary && market.trancheSummary[trancheType ? 'TRANCHE_A' : 'TRANCHE_B'].sliceApy)}% </h2>
                    <TooltipWrapper SLICEAPY tooltip={TooltipToggle === "SLICEAPY"} platform color={ModeThemes[theme].Tooltip} language={'en'}>
                        <div>
                            <h2> Annualized return of the rewards distributed to each tranche based on the Solvency Incentive Ratio</h2>
                        </div>
                    </TooltipWrapper>
                </HeaderOverviewContentCol>
                <span></span>
                <HeaderOverviewContentCol theme={theme} color={ModeThemes[theme].TableHead} value={ModeThemes[theme].HeaderTitle}>
                    <h2 onMouseOver={() => tooltipToggle("NETAPY")} onMouseLeave={() => tooltipToggle("")}>NET APY</h2>
                    <h2>{roundNumber(market.trancheSummary && market.trancheSummary[trancheType ? 'TRANCHE_A' : 'TRANCHE_B'].netApy)}% </h2>
                    <TooltipWrapper NETAPY tooltip={TooltipToggle === "NETAPY"} platform color={ModeThemes[theme].Tooltip} language={'en'}>
                        <div>
                            <h2>Expected yearly return on investment. Includes SLICE rewards APY</h2>
                        </div>
                    </TooltipWrapper>
                </HeaderOverviewContentCol>
                <span></span>
                <HeaderOverviewContentCol theme={theme} color={ModeThemes[theme].TableHead} value={ModeThemes[theme].HeaderTitle}>
                    <h2 onMouseOver={() => tooltipToggle("TVL")} onMouseLeave={() => tooltipToggle("")}>TOTAL VALUE LOCKED</h2>
                    <h2>${roundNumber(market.trancheSummary && market.trancheSummary[trancheType ? 'TRANCHE_A' : 'TRANCHE_B'].trancheValueUSD)}</h2>
                    <TooltipWrapper TVL tooltip={TooltipToggle === "TVL"} platform color={ModeThemes[theme].Tooltip} language={'en'}>
                        <div>
                            <h2>Value of all assets locked in the Tranche protocol</h2>
                        </div>
                    </TooltipWrapper>
                </HeaderOverviewContentCol>
            </HeaderOverviewContent>
        </HeaderOverviewWrapper>
        <TranchePerformanceWrapper>
            <TranchePerformanceContent>
                <PerformanceChart theme={theme} trancheType={trancheType} changeTranche={changeTranche} chartData={market && market.chartData}/>
            </TranchePerformanceContent>
        </TranchePerformanceWrapper>
          <MarketDetailsComponent theme={theme} trancheSummary={market.trancheSummary} chartData={market && market.sliceRewardsChartData} trancheType={trancheType }/>
    </HeaderWrapper>
  );
}


const mapStateToProps = (state) => ({
    trancheType: state.trancheType
});
  
export default connect(mapStateToProps, { changeTranche })(Header);