import React, { useEffect, useState } from 'react';
import { HomeURL, DocsURL, TrancheURL } from 'constants/urls';
import useAnalytics from 'services/analytics';
import { connect } from 'react-redux';
import {
  NavContainer,
  NavIcon,
  NavBrand,
  NavLinks,
  NavItem,
  NavMobileWrapper,
  NavMobileContent
} from './styled';
import i18n from "./locale/i18n";
import { LogoColored, LogoTranche } from 'assets';
import { ModeThemes } from 'constants/constants';

function Header({LogoNavbar, color, border, theme}) {
  const [NavbarIsActive, setNavbarActive] = useState(false); 
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900);
    const updateMedia = () => {
      setDesktop(window.innerWidth > 900);
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    });

  return (
    <NavContainer border={border}>
      <NavBrand href={HomeURL} path={window.location.pathname.split('/')[1] === 'analytics'}><img src={window.location.pathname.split(2)[0] === '/' ? LogoNavbar : (theme === 'dark' ? LogoTranche : LogoColored)} alt="Logo"/></NavBrand>

      {/* <NavIcon NavbarIsActive={NavbarIsActive} onClick={()=>setNavbarActive(!NavbarIsActive)} color={ModeThemes[theme].NavbarIcon} landing={window.location.pathname.split('/')[1] === ''}>
        <span></span>
        <span></span>
        <span></span>
      </NavIcon> */}
    {
      isDesktop &&
      <NavLinks active={NavbarIsActive}>
        <NavItem href={DocsURL} theme={theme} path={window.location.pathname.split('/')[1] === 'analytics'} color={ModeThemes[theme].NavbarAnalyticsColor} style={{color: color}} target="_blank">{i18n.t("navbar.documentation")}</NavItem>
        <NavItem href={TrancheURL} theme={theme} path={window.location.pathname.split('/')[1] === 'analytics'} color={ModeThemes[theme].NavbarAnalyticsColor} target="_blank" rel="noopener noreferrer" style={{color: color}} launch>Launch App</NavItem>
      </NavLinks>
    }
    {/* {
      !isDesktop &&
      <NavMobileWrapper NavbarIsActive={NavbarIsActive}>
        <NavMobileContent>
          <a href={TrancheURL} theme={theme} path={window.location.pathname.split('/')[1] === 'analytics'} target="_blank" rel="noopener noreferrer" onClick={(e) => Tracker("App", TrancheURL)}>Launch App</a>
          <a href={DocsURL} theme={theme} path={window.location.pathname.split('/')[1] === 'analytics'} target="_blank" rel="noopener noreferrer" onClick={(e) => Tracker("Documentation", DocsURL)}>{i18n.t("navbar.documentation")}</a>
        </NavMobileContent>
      </NavMobileWrapper>
    } */}

    </NavContainer>
    
  );
}

const mapStateToProps = (state) => ({
  theme: state.theme
});

export default connect(mapStateToProps, null)(Header);