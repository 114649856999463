import React from 'react';
import { ArrowDownTable, ArrowLinkTable, ArrowUpTable, DefaultIcon, InfoIconTable, Lock } from 'assets';
import {
    LandingTableCard,
    LandingTableCardMobileHeader,
    LandingTableCardMobileCrypto,
    LandingTableCardMobileProtocol,
    LandingTableCardMobileContent,
    LandingTableCardMobileContentCol,
    LandingTableCardMobileContentColValue,
    LandingTableCardMobileContentColSubValue,
    LandingLinkTab
} from '../../styled';
import { marketsLogos } from 'constants/constants';
import { roundNumber } from 'utils';
import { TrancheURL } from 'constants/urls';

function LandingTableCardCompMobile({ noBorder, network, contractAddress, cryptoType, trancheType, netAPY }) {
    let cryptoAvax = network === "avax" && cryptoType !== "AVAX";

    return (
        <LandingTableCard noBorder={noBorder}>
            <LandingTableCardMobileHeader>
                <LandingTableCardMobileCrypto>
                    <img src={marketsLogos[contractAddress] ? marketsLogos[contractAddress][cryptoType] : DefaultIcon} alt="logo" />
                    <h2>{cryptoType}
                        {
                            cryptoAvax && <span>.e</span>
                        }
                    </h2>
                </LandingTableCardMobileCrypto>
                <LandingLinkTab href={TrancheURL} margin="0" width="113px" height="32px" fontSize="10px" imgWidth="10px">
                    Deposit {cryptoType} {
                        cryptoAvax && <span>.e</span>
                    }
                    <img src={ArrowLinkTable} alt="icon"/>
                </LandingLinkTab>
            </LandingTableCardMobileHeader>
            
            <LandingTableCardMobileContent>
                <LandingTableCardMobileContentCol>
                <LandingTableCardMobileProtocol>
                    {   marketsLogos[contractAddress] &&
                        <img src={marketsLogos[contractAddress].protocolIcon} alt="protocol"/>
                    }
                    <h2>{marketsLogos[contractAddress] && marketsLogos[contractAddress
                    ].protocolName}</h2>
                </LandingTableCardMobileProtocol>
                </LandingTableCardMobileContentCol>
                <LandingTableCardMobileContentCol>
                    <LandingTableCardMobileContentColValue>
                        <span>{roundNumber(netAPY, 2)}%</span>
                    </LandingTableCardMobileContentColValue>
                    <LandingTableCardMobileContentColSubValue>
                        {trancheType === 'TRANCHE_A' ? 'Fixed' : 'Variable'}
                    </LandingTableCardMobileContentColSubValue>
                </LandingTableCardMobileContentCol>
            </LandingTableCardMobileContent>
        </LandingTableCard>
   );
}

export default LandingTableCardCompMobile;