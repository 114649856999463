import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../components/Analytics/AnalyticsPage/Header';
import { Layout } from '../components/Analytics/Layout';
import {  getTrancheSummary } from 'services/marketAnalytics';
import Footer from '../components/Footer/Footer';
import { changeTranche } from '../redux/actions/trancheType';
import {
  JCompoundAddress,
  JAaveAddress,
  JYearnAddress,
  JAvalancheAddress,
  JBenqiAddress
} from '../constants/constants'

function AnalyticsPage({ theme, changeTranche, match }) {
  // const [chart, setChart] = useState({})
  // const [summary, setSummary] = useState({})
  const [market, setMarket] = useState({})
  // const [network, setNetwork] = useState('ethereum')
  // let network = 
  // useEffect(() => {
  //   ReactGA.initialize(GoogleAnalyticsTrackingID, { gaOptions: { userId: address } });
  // }, [address]);

  useEffect(() => {
    const { id, network, type } = match.params;
    const qs = {};
    switch (network)
    {
      case "ethereum":
        qs[ 'contractAddress' ] = JCompoundAddress;
        break;
      case "polygon":
        qs[ 'contractAddress' ] = JAaveAddress;
        break;
      case "ftm":
        qs[ 'contractAddress' ] = JYearnAddress;
        break;
      case "avax":
        qs[ 'contractAddress' ] = JAvalancheAddress;
        break;
      case "benqi":
        qs[ 'contractAddress' ] = JBenqiAddress;
        break;
      default:
    };
    async function getTranche () {
      const n = network === 'benqi' ? 'avax' : network;
      const res = await getTrancheSummary(n, id, qs);
      setMarket(res.result)
    }
    getTranche();
    changeTranche(!(+type));
  }, [ changeTranche, match ]);

  return (
    <div>
      <Layout>
        <Header theme={theme} market={market}/>
      </Layout>
      <Footer />
    </div>
    
  );
}


const mapStateToProps = (state) => ({
  theme: state.theme
});

export default connect(mapStateToProps, {changeTranche})(withRouter(AnalyticsPage));