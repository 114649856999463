//Google Analytics

import { AAVE, CompoundLogo, USDCCArd, DAICARD, MaticTable, USDTCard, WBTC, Chainlink, YearnIcon, FantomIcon, USDCIcon, DAIIcon, MIMIcon, YFIIcon, AAVEIconTable, YearnIconTable, CompoundIconTable, YFI, MIM, TAAVAX, TAWETH, TABTC, TAVAX, TWETH, TBTC, BenqiIcon, BenqiTokenIcon } from 'assets';
export const GoogleAnalyticsTrackingID = 'UA-197572899-1';
export const GTMID = 'GTM-NFDMGZN';

export const LandingModeThemes = {
  light: {
    body: "#000000"
  },
  dark: {
    body: "#000000"
  }
}

export const ModeThemes = {
    light: {
      body: '#F9F9FE',
      landing: '#FFFFFF',
      navlinkTab: 'rgb(68, 65, 207)',
      HeaderTitle: '#393F56',
      HeaderSubtitle: '#9496B6',
      TableHead: 'rgba(36, 39, 50, 0.6)',
      TableCard: '#FFFFFF',
      TableCardBorderColor: '#F0F0F7',
      footerBackground: '#F6F6FC',
      footerLinks: '#AAA8E9',
      languageToggleBackground: 'rgba(68,65,207,0.1)',
      languageToggleText: '#4441CF',
      activeStatus: '#DDFFEB',
      NavbarIcon: "#4441CF",
      activeStatusText: '#2ECC71',
      tableText: '#39295A',
      tableCardShadow: '0px 4px 4px rgb(189 189 189 / 7%)',
      dropDownBorder: '#F0F0F0',
      dropDownText: '#39295A',
      inputBackground: 'rgb(255, 255, 255)',
      inputDisabledBackground: 'rgba(207,207,229,0.2)',
      borderColor: '#EFEFEF',
      backgroundBorder: '#CCCCCD',
      titleSectionText: 'rgba(124, 133, 155, 0.8)',
      valueSectionText: '#393F56',
      titleColor: '#393F56',
      textColor: '#7C859B',
      ModalBackground: '#FFFFFF',
      TrancheRateTypeColor: "rgba(106, 103, 255, 0.9)",
      ModalText: '#4F4F4F',
      SelectedStaking: 'rgba(68, 65, 207, 0.05)',
      SelectedStakingText: '#39295A',
      SelectedStakingLink: '#776E8B',
      borderInputColor: '#EAEAEA;',
      StakingInputText: '#838186',
      loadingSpinner: 'rgba(124,133,155,0.8)',
      Tooltip: '#2F2F2F',
      NavbarBorder: '#E9E9FC',
      NavbarBackground: '#FFFFFF',
      NavbarShadow: '0px 3.49091px 3.49091px rgba(189, 189, 189, 0.07)',
      TrancheMarketsTitle: '#393F56',
      TrancheModalLinkColor: '#4939D7',
      TrancheModalLinkBackground: '#E7E7F9',
      BorderStake: "#CFCFE5",
      TrancheBtnBackground: '#FFFFFF',
      TrancheBtnBackgroundCurrent: 'rgba(255, 255, 255, 0.5);',
      TrancheBtnBorder: '#E9E9FC',
      TrancheBtnColor: '#000000',
      inputText: "#838186",
      BoxColorText: "#393F56",
      EstimatedColor: "#000000",
      boxText: "#776E8B",
      BoxColor: "rgba(68, 65, 207, 0.05)",
      TableHeadText: "rgba(79, 79, 79, 0.6)",
      TrancheBtnSpan: '#E9E9FC',
      ClaimHead: 'rgba(36, 39, 50, 0.6)',
      StakingMax: 'rgba(57,41,90,0.3)',
      StakeModalNavigationText: "#4441CF",
      HowTo: '#FFFFFF',
      HowToText: '#4441CF',
      HowToBorder: '0.872727px solid #E9E9FC',
      HowToShadow: '0px 3.49091px 3.49091px rgba(189, 189, 189, 0.07)',
      StakeModalNavigationBorder: "#E9E9FC",
      stakeModalBoxShadow: "0px 2.14578px 2.14578px rgba(189, 189, 189, 0.07)",
      stakeModalBorderCurrent: "0.536446px solid #E9E9FC",
      stakeModalBoxBackground: "#F9F9FE",
      stakeBoxBackground: "#FFFFFF",
      MigrateInput: "#F5F0FF",
      LoadingColorOne: '#eee',
      LoadingColorTwo: '#f7f7f7',
      docsLockupText: "#4441CF",
      docsLockupBackground: "rgba(68, 65, 207, 0.1)",
      cardShadow: '0 -1px 12px 0 rgb(0 0 0 / 10%), 0 0 12px 0 transparent',
      btnShadow: '0px 4px 4px rgb(189 189 189 / 30%)',
      MigrateProgressTextActive: "#39295A",
      MigrateProgressTextPending: "#9F9DE6",
      MigrateProgressLine: "#D4D4D4",
      MigrateStepBorder: "rgba(0, 0, 0, 0.2)",
      disabledBtnColor: "rgba(204,204,205,1)",
      MigrateStepTextPending: "rgba(0, 0, 0, 0.5)",
      MigrateStepText: "#FFFFFF",
      ModalTrancheTextColor: "#393F56",
      MigrateStepBackground: "#FFFFFF",
      MigrateContentTitle: "#393F56",
      MigrateClaimCardBackground: "rgba(138, 94, 234, 0.05)",
      MigrateClaimCardTitle: "rgba(124, 133, 155, 0.8)",
      ModalTrancheNavbarBtnBorder: "0.872727px solid #E9E9FC",
      ModalTrancheNavbarBtn: "#FFFFFF",
      MigrateClaimCardValue: "#393F56",
      ModalTrancheNavbarBtnText: "#393F56",
      TrancheRateFixedColor: "rgba(106, 103, 255, 0.9)",
      toggleBackground: '#F9F9FE',
      TrancheRateVariableColor: "rgba(118, 77, 232, 0.9)",  
      ModalTrancheTextRowBorder: "#F0F0F0",
      ModalTrancheNavbarBtnShadow: "0px 3.49091px 3.49091px rgba(189, 189, 189, 0.07)",
      CongratsText: "rgba(57, 63, 86, 0.8)",
      NavbarAnalyticsColor: "#4441CF",
      carouselTitleColor: "rgba(57, 63, 86, 0.5)",
      AnalyticsHeaderBackground: "#FFFFFF",
      SliderColor: "rgba(68, 65, 207, 0.1)",
    },
    dark: {
      body: '#100F36',
      landing: '#000000',
      navlinkTab: '#FFFFFF',
      ModalTrancheNavbarBtn: "rgba(175, 155, 255, 0.15)",
      ModalTrancheNavbarBtnShadow: "",
      carouselTitleColor: "#FFFFFF",
      NavbarAnalyticsColor: "#FFFFFF",
      ModalTrancheTextRowBorder: "rgba(249, 249, 251, 0.1)",
      SliderColor: "rgba(255, 255, 255, 0.1)",
      toggleBackground: ' rgba(255, 255, 255, 0.05)',
      ModalTrancheNavbarBtnBorder: "",
      TrancheRateFixedColor: "rgba(106, 103, 255, 0.4)",
      TrancheRateVariableColor: "rgba(118, 77, 232, 0.4)",
      ModalTrancheTextColor: "#FFFFFF",
      ModalTrancheNavbarBtnText: "#FFFFFF",
      AnalyticsHeaderBackground: "rgba(233,233,252,0.05)",
      HeaderTitle: '#FFFFFF',
      StakeModalNavigationText: "#C2C4DA",
      docsLockupText: "#FFFFFF",
      disabledBtnColor: "rgba(204,204,205,0.15)",
      docsLockupBackground: "rgba(105,103,156,0.4)",
      HeaderSubtitle: '#C2C4DA',
      TableHeadText: "rgba(255, 255, 255, 0.6)",
      stakeModalBoxShadow: "",
      stakeBoxBackground: "rgba(175, 155, 255, 0.15);",
      stakeModalBorderCurrent: "",
      stakeModalBoxBackground: "rgba(175, 155, 255, 0.15)",
      BoxColor: "rgba(196, 149, 255, 0.15)",
      BoxColorText: "#CEC6FF",
      boxText: "#CEC6FF",
      MigrateInput: "rgba(255, 255, 255, 0.05)",
      TrancheRateTypeColor: "rgba(106, 103, 255, 0.4)",
      StakeModalNavigationBorder: "rgba(255, 255, 255, 0.1)",
      EstimatedColor: "rgba(255, 255, 255, 0.8)",
      inputText: "rgba(255, 255, 255, 0.8)",
      TableHead: 'rgba(255, 255, 255, 0.6)',
      TableCard: 'rgba(255, 255, 255, 0.07)',
      TableCardBorderColor: '#363661',
      footerBackground: '#07052F',
      BorderStake: "rgba(255, 255, 255, 0.5)",
      footerLinks: 'rgba(255, 255, 255, 0.6)',
      languageToggleBackground: 'rgba(134, 132, 255, 0.25)',
      languageToggleText: '#FFFFFF',
      activeStatus: 'rgba(46, 204, 113, 0.9)',
      activeStatusText: '#FFFFFF',
      tableText: '#FFFFFF',
      tableCardShadow: '',
      dropDownBorder: '#363661',
      borderInputColor: 'rgba(255, 255, 255, 0.5)',
      dropDownText: '#FFFFFF',
      inputBackground: 'rgba(255, 255, 255, 0.5)',
      inputDisabledBackground: 'transparent',
      borderColor: 'rgba(204, 204, 205, 0.15)',
      backgroundBorder: 'rgba(204, 204, 205, 0.15)',
      titleSectionText: 'rgba(255, 255, 255, 0.8);',
      valueSectionText: '#FFFFFF',
      titleColor: '#FFFFFF',
      textColor: '#C2C4DA',
      ModalBackground: '#221F46',
      ModalText: '#FFFFFF',
      SelectedStaking: 'rgba(151, 149, 255, 0.15)',
      SelectedStakingText: '#FFFFFF',
      SelectedStakingLink: '#CEC6FF',
      StakingInputText: 'rbga{255, 255, 255, 0.5}',
      loadingSpinner: 'rgba(255,255,255,0.3)',
      Tooltip: '#383765',
      NavbarBorder: 'rgba(255, 255, 255, 0.1)',
      NavbarBackground: 'rgba(175, 155, 255, 0.15)',
      NavbarShadow: 'none',
      TrancheMarketsTitle: '#FFFFFF',
      TrancheModalLinkColor: '#FFFFFF',
      TrancheModalLinkBackground: 'rgba(202, 197, 255, 0.13)',
      TrancheBtnBackground: '#1C1B40',
      TrancheBtnBackgroundCurrent: '#322E5D',
      TrancheBtnBorder: '#322E5D',
      TrancheBtnColor: '#FFFFFF',
      TrancheBtnSpan: '#363661',
      ClaimHead: 'rgba(255, 255, 255, 0.6)',
      StakingMax: 'rgb(57,41,90)',
      HowTo: 'rgba(175, 155, 255, 0.15)',
      HowToText: '#FFFFFF',
      NavbarIcon: "#FFFFFF",
      HowToBorder: 'none',
      HowToShadow: 'none',
      LoadingColorOne: 'rgba(255,255,255,0.07)',
      LoadingColorTwo: '#363661',
      cardShadow: '0 -1px 12px 0 rgb(255 255 255 / 15%), 0 0 12px 0 transparent',
      btnShadow: '',
      MigrateProgressTextActive: "#FFFFFF",
      MigrateProgressTextPending: "rgba(255, 255, 255, 0.5)",
      MigrateProgressLine: "rgba(255, 255, 255, 0.5)",
      MigrateStepBorder: "rgba(255, 255, 255, 0.2)",
      MigrateStepTextPending: "rgba(255, 255, 255, 0.5)",
      MigrateStepText: "#FFFFFF",
      MigrateStepBackground: "#2A2254",
      MigrateContentTitle: "#FFFFFF",
      MigrateClaimCardBackground: "rgba(255, 255, 255, 0.04)",
      MigrateClaimCardTitle: "rgba(255,255,255,0.5)",
      MigrateClaimCardValue: "#FFFFFF",
      CongratsText: "rgba(255, 255, 255, 0.8)"
    }
  };
  
  export const trancheIcons = {
    cDAI: { protocolIcon: CompoundLogo, assetIcon: DAICARD },
    cUSDC: { protocolIcon: CompoundLogo, assetIcon: USDCCArd },
    cUSDT: { protocolIcon: CompoundLogo, assetIcon: USDTCard },
    amMATIC: { protocolIcon: AAVE, assetIcon: MaticTable },
    amWMATIC: { protocolIcon: AAVE, assetIcon: MaticTable },
    amDAI: { protocolIcon: AAVE, assetIcon: DAICARD },
    amUSDC: { protocolIcon: AAVE, assetIcon: USDCCArd },
    cWBTC: { protocolIcon: CompoundLogo, assetIcon: WBTC },
    cLINK: { protocolIcon: CompoundLogo, assetIcon: Chainlink },
    yvWFTM: { protocolIcon: YearnIcon, assetIcon: FantomIcon },
    yvUSDC: { protocolIcon: YearnIcon, assetIcon: USDCIcon },
    yvDAI: { protocolIcon: YearnIcon, assetIcon: DAIIcon },
    yvMIM: { protocolIcon: YearnIcon, assetIcon: MIMIcon },
    yvYFI: { protocolIcon: YearnIcon, assetIcon: YFIIcon },
    avWAVAX: { protocolIcon: AAVE, assetIcon: TAVAX },
    avWETH: { protocolIcon: AAVE, assetIcon: TWETH },
    avWBTC: { protocolIcon: AAVE, assetIcon: TBTC },
    avDAI: { protocolIcon: AAVE, assetIcon: DAICARD },
    avUSDT: { protocolIcon: AAVE, assetIcon: USDTCard },
    avUSDC: { protocolIcon: AAVE, assetIcon: USDCCArd },
    avAAVE: { protocolIcon: AAVE, assetIcon: AAVE },
    qiDAI: { protocolIcon: BenqiTokenIcon, assetIcon: DAICARD },
    qiBTC: { protocolIcon: BenqiTokenIcon, assetIcon: WBTC },
    qiAVAX: { protocolIcon: BenqiTokenIcon, assetIcon: TAVAX },
    qiETH: { protocolIcon: BenqiTokenIcon, assetIcon: TWETH },
    qiUSDT: { protocolIcon: BenqiTokenIcon, assetIcon: USDTCard },
    qiUSDC: { protocolIcon: BenqiTokenIcon, assetIcon: USDCCArd },
    qiLINK: { protocolIcon: BenqiTokenIcon, assetIcon: Chainlink },
    qiQI: { protocolIcon: BenqiTokenIcon, assetIcon: BenqiTokenIcon },
  };


export const JCompoundAddress = process.env.REACT_APP_COMPOUND_TRANCHE_ADDRESS.toLowerCase();
export const JAaveAddress = process.env.REACT_APP_AAVE_TRANCHE_ADDRESS.toLowerCase();
export const JYearnAddress = process.env.REACT_APP_YEARN_TRANCHE_ADDRESS.toLowerCase();
export const JAvalancheAddress = process.env.REACT_APP_AVALANCHE_TRANCHE_ADDRESS.toLowerCase();
export const JBenqiAddress = process.env.REACT_APP_BENQI_TRANCHE_ADDRESS.toLowerCase();


const compound = {
  DAI: DAICARD,
  USDT: USDTCard,
  USDC: USDCCArd,
  WBTC: TBTC,
  LINK: Chainlink,
  protocolIcon: CompoundIconTable,
  protocolName: 'Compound'
};
const aave = {
  DAI: DAICARD,
  USDT: USDTCard,
  USDC: USDCCArd,
  MATIC: MaticTable,
  protocolIcon: AAVEIconTable,
  protocolName: 'AAVE'
};
const yearn = {
  DAI: DAICARD,
  USDT: USDTCard,
  USDC: USDCCArd,
  YFI: YFI,
  WFTM: FantomIcon,
  MIM: MIM,
  protocolIcon: YearnIconTable,
  protocolName: 'Yearn'
};
const avalanche = {
  AVAX: TAVAX,
  WETH: TWETH,
  WBTC: TBTC,
  DAI: DAICARD,
  USDT: USDTCard,
  USDC: USDCCArd,
  AAVE: AAVE,
  protocolIcon: AAVEIconTable,
  protocolName: 'AAVE'
};
const benqi = {
  AVAX: TAVAX,
  WETH: TWETH,
  WBTC: TBTC,
  DAI: DAICARD,
  USDT: USDTCard,
  USDC: USDCCArd,
  AAVE: AAVE,
  LINK: Chainlink,
  QI: BenqiTokenIcon,
  protocolIcon: BenqiIcon,
  protocolName: 'BENQI'
};

export const marketsLogos = {};

marketsLogos[JCompoundAddress] = compound;
marketsLogos[JAaveAddress] = aave;
marketsLogos[JYearnAddress] = yearn;
marketsLogos[JAvalancheAddress] = avalanche;
marketsLogos[JBenqiAddress] = benqi;