import { getRequest, postRequest } from './axios';
import { ApiURL } from '../constants/urls';

export const getTVLSummary = (days) => {
  return getRequest(`${ApiURL}analytics/summary${days? `?${days}`: ''}`).then(res => res.data);
}

export const getTrancheList = (data, days) => {
  return postRequest(`${ApiURL}analytics/tranche-list${days? `?${days}`: ''}`, { data }).then(res => res.data);;
}

export const getTrancheSummary = (network, trancheId, qs = {}) => {
  return getRequest(`${ApiURL}analytics/tranche-summary/${network}/${trancheId}`, { qs }).then(res => res.data);;
}