import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  GlobalStyle} from 'components';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LandingComponent from 'components/Landing/Landing';
import { LandingModeThemes, ModeThemes } from 'constants/constants';
import { ThemeProvider } from 'styled-components';

// import Analytics from './Pages/Analytics';
import 'App.css';
import AnalyticsPage from './Pages/AnalyticsPage';
function App({ theme }) {
  const [pathnameLanding, setPathnameLanding] = useState(false);

  const baseRouteUrl = '/:locale(zh|kr|en)?';
  useEffect(() =>{
    let pathname = window.location.pathname === '/' ? true : false
    setPathnameLanding(pathname);
  }, [])
  return (
    <ThemeProvider theme={pathnameLanding ? LandingModeThemes[theme] : ModeThemes[theme]}>
      <GlobalStyle />
      <Router>
          <Switch>
            <Route exact path={baseRouteUrl} component={LandingComponent} />
            {/* <Route exact path="/analytics" component={Analytics} /> */}
            <Route exact path="/analytics/:network/:id" component={AnalyticsPage} />
            <Route exact path="/analytics/:network/:id/:type" component={AnalyticsPage} />
            <Redirect from='*' to='/' />
          </Switch>
        </Router>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  theme: state.theme
});

export default connect(mapStateToProps, null)(App);