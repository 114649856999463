import React from 'react';
import Header from 'components/Header';
import { Container,
  LayoutWrapper,
  LayoutContent,
} from './styled';
import { HeaderMap } from 'assets';
import HeaderMapSVG from './Landing/Header/HeaderMapSVG';

export function Layout({ children, LogoNavbar, NavbarColor, Caret }) {
  return (
    <LayoutWrapper>
        <LayoutContent background={HeaderMap}>
            <HeaderMapSVG />
            <Container className="container">
              <Header Caret={Caret} LogoNavbar={LogoNavbar} color={NavbarColor}/>
            </Container>
              {children}
            {/* <Footer color={FooterColor} /> */}
        </LayoutContent>
    </LayoutWrapper>
  );
}