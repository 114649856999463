import BigNumber from 'bignumber.js';

export const roundNumber = (input, roundTo, type = false) => {
  try {
    if (input === 'N/A') return;
    if (typeof input === 'string') input = Number(input);
    let decimalPoints = 0;
    if (!roundTo && input % 10 !== 0) {
      const value = Math.floor(input);
      decimalPoints = input % 1 === 0 ? 0 : Math.max(5 - `${ value === 0 ? '' : value }`.length, 0);
    } else decimalPoints = roundTo;
    if (type) {
      let result = safeMultiply(input, 10 ** decimalPoints);
      if (type === 'up') {
        if (Number(result.toString().split('.')[0]) === Math.ceil(result)) result = safeAdd(result, 0.1);
        result = Math.ceil(result);
      }
      if (type === 'down') result = Math.floor(result);
      result = safeDivide(result, 10 ** decimalPoints);
      return result;
    }
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints
    });
    return formatter.format(input);
  } catch (error) {
    console.error(error);
  }
};
  export const safeAdd = function (a, b) {
    let x = new BigNumber(a);
    let y = new BigNumber(b);
    return x.plus(y);
  };
  export const safeSubtract = function (a, b) {
    let x = new BigNumber(a);
    let y = new BigNumber(b);
    return x.minus(y);
  };
  export const safeDivide = function (a, b) {
    let x = new BigNumber(a);
    let y = new BigNumber(b);
    return x.dividedBy(y);
  };
  export const safeMultiply = function (a, b) {
    let x = new BigNumber(a);
    let y = new BigNumber(b);
    return x.multipliedBy(y);
  };