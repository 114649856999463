import { AAVE, CompoundIconCard, EthIconCard, FantomIconCard, AVAX, IntegrationsCardBorder, PolygonIconCard, YearnIconCard } from 'assets';
import React from 'react';
import {
    TrancheIntegrationsWrapper,
    TrancheIntegrationsTitle,
    TrancheIntegrationsCards,
    TrancheIntegrationsCard,
    TrancheIntegrationsCardTitle,
    TrancheIntegrationsCardContent,
    TrancheIntegrationsText,
    TrancheIntegrationsLogo
} from '../styled';


function TrancheIntegrations() {
    return (
        <TrancheIntegrationsWrapper>
            <TrancheIntegrationsTitle>Tranche's Integrations</TrancheIntegrationsTitle>
            <TrancheIntegrationsCards>
                <TrancheIntegrationsCard border={IntegrationsCardBorder}>
                    <TrancheIntegrationsCardTitle>Networks</TrancheIntegrationsCardTitle>
                    <TrancheIntegrationsCardContent>
                        <TrancheIntegrationsLogo>
                            <img src={EthIconCard} alt="icon"/>
                            <h2>Mainnet</h2>
                        </TrancheIntegrationsLogo>
                        <TrancheIntegrationsLogo>
                            <img src={PolygonIconCard} alt="icon"/>
                            <h2>Polygon</h2>
                        </TrancheIntegrationsLogo>
                        <TrancheIntegrationsLogo>
                            <img src={FantomIconCard} alt="icon"/>
                            <h2>Fantom</h2>
                        </TrancheIntegrationsLogo>
                         <TrancheIntegrationsLogo>
                            <img src={AVAX} alt="icon"/>
                            <h2>Avalanche</h2>
                        </TrancheIntegrationsLogo>
                    </TrancheIntegrationsCardContent>
                </TrancheIntegrationsCard>

                <TrancheIntegrationsCard border={IntegrationsCardBorder}>
                    <TrancheIntegrationsCardTitle>Markets</TrancheIntegrationsCardTitle>
                    <TrancheIntegrationsCardContent>
                        <TrancheIntegrationsLogo>
                            <img src={YearnIconCard} alt="icon"/>
                            <h2>Yearn</h2>
                        </TrancheIntegrationsLogo>
                        <TrancheIntegrationsLogo imgWidth="40px">
                            <img src={AAVE} alt="icon"/>
                            <h2>AAVE</h2>
                        </TrancheIntegrationsLogo>
                        <TrancheIntegrationsLogo>
                            <img src={CompoundIconCard} alt="icon"/>
                            <h2>Compound</h2>
                        </TrancheIntegrationsLogo>
                    </TrancheIntegrationsCardContent>
                </TrancheIntegrationsCard>
            </TrancheIntegrationsCards>
        </TrancheIntegrationsWrapper>
    );
}

export default TrancheIntegrations;