import React, { useEffect, useState } from 'react';
// import useAnalytics from 'services/analytics';
import {
    Container,
    LandingWrapper,
    LandingContent
} from '../styled'
import {
    Layout 
} from 'components';
import { Logo } from 'assets';
import 'animate.css';
import HeaderWrapper from './Header/HeaderWrapper';
import ScrollIcon from './ScrollIcon';
import WhatTrancheDoes from './WhatTrancheDoes';
import LandingTable from './LandingTable/LandingTable';
import TrancheIntegrations from './TrancheIntegrations';
import SecurityAudit from './SecurityAudit';
import Footer from './Footer/Footer';
import { getMarketList } from 'services/landing';

// import i18n from "./locale/i18n";

function LandingComponent() {
    // const Tracker = useAnalytics("ExternalLinks");
    const [loading, setLoading] = useState(false);
    const [marketList, setMarketList] = useState([]);

    const scrollPage = () => {
        let sectionToJump = document.getElementById('WhatTrancheDoes');
        sectionToJump.scrollIntoView();
    }
    useEffect(() => {
        setLoading(true);
        async function getLandingMarketList() {
          const res = await getMarketList();
          setMarketList(res.result);
          setLoading(false);
        }
        getLandingMarketList();
    }, []);
    return (
        <Layout LogoNavbar={Logo}>
            <LandingWrapper>
                <LandingContent>
                    <Container className="container">
                        <HeaderWrapper />
                        <ScrollIcon scrollPage={scrollPage}/>
                        <WhatTrancheDoes />
                        <LandingTable marketList={marketList} loading={loading}/>
                        <TrancheIntegrations />
                        <SecurityAudit />
                        <Footer />
                    </Container>
                </LandingContent>
            </LandingWrapper>
        </Layout>
   );
}

export default LandingComponent;